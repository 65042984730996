import { defineStore } from 'pinia';
import Vue from 'vue';

export const useBrandingsStore = defineStore('brandings', {
  state: () => ({
    lastUsedBranding: null,
    isLoadingOrganizationBranding: false,
    isLoadingUserBranding: false,

    organizationBrandingsList: [],
    userBrandingsList: [],
    isLoadedOrganizationBranding: false,
    isLoadedUserBranding: false,

    defaultLogo: 'static/imgs/harbour-logo-blue.png',
    defaultBanner: 'static/imgs/harbour-banner-default-2.jpg',

    organizationDefaultBranding: null,    
  }),

  getters: {
    getDashboardPointsData: (state) => state.dashboardGeoPointsData,
    hasAnalytics: (state) => {
      return (
        state.totalCompleted > 0 && state.totalSignersForPeriod > 0 && state.linksGeoData.length > 0
      );
    },
    defaultBranding: (state) => {
      return {
        banner_url: state.defaultBanner,
        logo_url: state.defaultLogo,
        id: 'branding-default-1236',
      }
    }
  },

  actions: {
    async getAllBrandings(force = false) {
      if (!force && this.isLoadedOrganizationBranding && this.isLoadedUserBranding) return
      await this.getAllOrganizationBrandings();
      await this.getAllUserBrandingList();
    },

    async getAllOrganizationBrandings() {
      let responseData = null;
      try {
        const responseData = await this.requestAllOrganizationBrandings();
        this.organizationBrandingsList = responseData?.org_brandings;
        this.isLoadedOrganizationBranding = true;
      } catch (error) {
        console.error(error);
        throw error;
      } finally {
        if (!responseData?.org_brandings?.length) {
          this.organizationBrandingsList = [];
        }
      }
    },

    async requestAllOrganizationBrandings() {
      try {
        this.isLoadingOrganizationBranding = true;
        return await doRequest();
      } finally {
        this.isLoadingOrganizationBranding = false;
      }

      async function doRequest() {
        const res = await Vue.prototype.$harbourData.post(
          'data?agreement_branding_get_all_organization_brandings',
          {
            requesttype: 'agreement_branding_get_all_organization_brandings',
          },
        );
        return res.data;
      }
    },

    async getAllUserBrandingList() {
      let responseData = null;
      try {
        responseData = await this.requestAllUserBrandings();
        this.userBrandingsList = responseData?.agreements_brandings;
        this.isLoadedUserBranding = true;
      } catch (error) {
        console.error(error);
        throw error;
      } finally {
        if (!responseData?.agreements_brandings?.length) {
          this.userBrandingsList = [
            this.defaultBranding
          ];
        }
      }
    },

    async requestAllUserBrandings() {
      try {
        this.isLoadingUserBranding = true;
        return await doRequest();
      } finally {
        this.isLoadingUserBranding = false;
      }

      async function doRequest() {
        const res = await Vue.prototype.$harbourData.post(
          'data?agreement_branding_get_all_agreements_and_agreelinks_brandings',
          {
            requesttype: 'agreement_branding_get_all_agreements_and_agreelinks_brandings',
          },
        );
        return res.data;
      }
    },

    async getOrganizationDefaultBranding() {
      if (this.organizationDefaultBranding) {
        return this.organizationDefaultBranding
      }
      
      let branding = null;
      try {
        const responseData = await this.requestOrganizationDefaultBranding();
        branding = responseData?.branding;      
      } catch (error) {
        console.error(error);
        throw error;
      } finally {
        this.organizationDefaultBranding = branding ?? this.defaultBranding; 
      }
      return this.organizationDefaultBranding
    },

    async requestOrganizationDefaultBranding() {
      const res = await Vue.prototype.$harbourData.post('data?agreement_branding_get_default_org_branding', {
        requesttype: 'agreement_branding_get_default_org_branding',
      });
      return res.data;
    },


    async getAgreementBranding(agreementId) {
      let branding = null;
      try {
        const responseData = await this.requestAgreementBranding(agreementId);
        branding = responseData?.branding; 
      } catch (error) {
        console.error(error);
        throw error;
      } finally {
        branding = branding ?? this.defaultBranding
      }
      return branding
    },

    async requestAgreementBranding(agreementId) {
      const res = await Vue.prototype.$harbourData.post('data?agreement_branding_get_branding', {
        requesttype: 'agreement_branding_get_branding',
        agreementid: agreementId,
      });
      return res.data;
    },

  },
});

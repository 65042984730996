<script setup>
const props = defineProps({
  colId: {
    type: String,
    required: true
  },
  gridApi : {
    type: Object,
    required: true
  },
  gridColumnApi: {
    type: Object,
    required: true
  }
});

const hideColumn = () => {
  props.gridColumnApi.setColumnVisible(props.colId, false);
  props.gridApi.hidePopupMenu();
};

</script>
<template>
  <div class="popup-head">
    <span>Column options</span>
    <div
      v-if="!['name', 'title'].includes(props.colId)"
      class="icon-wrap"
      @click="hideColumn"
    >
      <b-tooltip
        label="Clicking hide will remove this column from view"
        type="is-dark"
        position="is-left"
        append-to-body
      >
        <i class="fa-sharp fa-regular fa-eye-slash"></i>
      </b-tooltip>
    </div>
  </div>
</template>
<style lang="postcss" scoped>
.popup-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 1px solid #DBDBDB;
  font-size: 16px;
  font-weight: 600;
  color: #333;
}
.icon-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #CCCCCC;
  cursor: pointer;
}
</style>

// src/constants.js

export const PAYMENT_STATUS = {
  DRAFT: 'draft', // The invoice is in a draft state and hasn’t been finalized.
  OPEN: 'open', // The invoice has been finalized and is awaiting payment.
  PAID: 'paid', // The invoice has been fully paid.
  UNCOLLECTIBLE: 'uncollectible', // The invoice is marked as uncollectible.
  VOID: 'void', // The invoice has been voided and will not be paid.
  UNPAID: 'unpaid', // The invoice is open and past due, but not yet marked as uncollectible.
  DELETED: 'deleted', // The invoice is deleted.
};

export const STATUS = {
  ACTIVE: 'active', // The subscription is in good standing and the most recent payment is successful.
  CANCELED: 'canceled', // The subscription has been canceled.
  INCOMPLETE: 'incomplete', // The initial payment for the subscription failed.
  INCOMPLETE_EXPIRED: 'incomplete_expired', // The initial payment for the subscription failed and the payment window has expired.
  PAST_DUE: 'past_due', // Payment on the latest finalized invoice either failed or wasn’t attempted
  TRIALING: 'trialing', // The subscription is currently in a trial period.
  UNPAID: 'unpaid', // The latest invoice hasn’t been paid but the subscription remains in place.
  PAUSED: 'paused', // The subscription has ended its trial period without a default payment method.
};

export const PLAN_TYPE = {
  STARTER: 'starter',
  PLUS: 'plus',
  ENTERPRISE: 'enterprise',
};

const DOCX_CONVERTER_URL = 'https://docx-converter.cke-cs.com/v2/convert/html-docx';
const DOCX_CONVERTER_CSS_PATH = '/src/assets/css/ckeditor/docx-converter.css';
const DOCX_CONVERTER_OPTIONS = {
  document: {
    size: 'A4',
    orientation: 'portrait',
    margins: {
      top: '2cm',
      bottom: '2cm',
      left: '1.6cm',
      right: '1.6cm',
    },
  },
};

export { DOCX_CONVERTER_URL, DOCX_CONVERTER_CSS_PATH, DOCX_CONVERTER_OPTIONS };

import Vue from 'vue';

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};

export const authUtils = {
  getOauth2Token() {
    return getCookie('oauth2token');
  },

  async ensureValidToken() {
    let token = this.getOauth2Token();
    if (!token) {
      // If no token, try to refresh it
      await this.refreshOauth2Token();
      token = this.getOauth2Token();
    }
    return token;
  },

  async refreshOauth2Token() {
    try {
      await Vue.prototype.$harbourData.post('/oauth2token');
      // The backend should set the new cookie
    } catch (ex) {
      console.error(`Failed to refresh API token: ${ex}`);
      throw ex;
    }
  },

  async makeAuthenticatedRequest(method, url, payload = {}, params = {}) {
    let token = await this.ensureValidToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const { data } = await Vue.prototype.$harbourData[method](url, payload, params, {
        headers: headers,
      });
      return data;
    } catch (err) {
      if (err.response && err.response.status === 401) {
        // Token might be expired, refresh and retry
        await this.refreshOauth2Token();
        token = this.getOauth2Token();
        if (token) {
          headers.Authorization = `Bearer ${token}`;
          const { data } = await Vue.prototype.$harbourData[method](url, payload, params, {
            headers: headers,
          });
          return data;
        }
      }
      console.error(`Error in ${method} request to ${url}:`, err);
      throw err;
    }
  },
};

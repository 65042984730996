import Vue from 'vue';
const endpointUrl = '/api/subscriptions';

export default {
  async listSubscriptions(params = {}) {
    const { data } = await Vue.prototype.$harbourData.get(endpointUrl, {}, params).catch((err) => {
      throw err;
    });
    return data;
  },

  async getSubscription(id) {
    const { data } = await Vue.prototype.$harbourData.get(`${endpointUrl}/${id}`).catch((err) => {
      throw err;
    });
    return data;
  },

  async createSubscription(payload = {}, params = {}) {
    const { data } = await Vue.prototype.$harbourData
      .post(endpointUrl, payload, params)
      .catch((err) => {
        throw err;
      });
    return data;
  },

  async updateSubscription(id, payload = {}, params = {}) {
    const { data } = await Vue.prototype.$harbourData
      .put(`${endpointUrl}/${id}`, payload, params)
      .catch((err) => {
        throw err;
      });
    return data;
  },

  async deleteSubscription(id, payload = {}, params = {}) {
    const { data } = await Vue.prototype.$harbourData
      .delete(`${endpointUrl}/${id}`, payload, params)
      .catch((err) => {
        throw err;
      });
    return data;
  },
};

import { ModalProgrammatic as Modal } from 'buefy';
import { defineStore } from 'pinia';

import { useHarbourStore } from '@/stores/harbour-store';
import { fieldAnnotationHelpers } from '@harbour-enterprises/superdoc';
import dateFormat from 'dateformat';
import SuperdocContainer from '@components/SuperdocContainer/SuperdocContainer.vue';


export const useSuperdocStore = defineStore('superdoc', {
  state: () => ({
    harbourStore: useHarbourStore(),
  }),

  getters: {
    hasSuperDocBeta: (state) => !!state.harbourStore.getContextDict?.superdoc_beta,
  },


  actions: {
    /**
     * Creates file object from file url.
     * @param url The file url.
     * @param name The file name.
     * @param type The file mimetype.
     * @returns File object.
     */
    async createFileObject(url, name, type) {
      return await fetch(url)
        .then((resp) => resp.blob())
        .then((blob) => new File([blob], name, { type }))
        .catch(() => null);
    },

    /**
     * Adds annotation to superdoc editor.
     * @param options.editor The SuperEditor instance.
     * @param options.input The input object.
     * @param options.pos The position to insert.
     * @param options.inputId The input id (optional, used for checkboxes).
     */
    addSuperdocEditorAnnotation({
      editor,
      input,
      pos,
      inputId = null,
    }) {
      let fieldId = inputId ?? input.id;
      let fieldType = input.itemfieldtype;
      let fieldColor = input.itemcolor;
      let displayLabel = input.itemdisplaylabel;

      let typeMap = {
        SIGNATUREINPUT: 'signature',
        IMAGEINPUT: 'image',
        CHECKBOXINPUT: 'checkbox',
        HTMLINPUT: 'html',
        DEFAULT: 'text',
      };
      let type = typeMap[fieldType] ?? typeMap.DEFAULT;

      if (type === 'signature') {
        displayLabel = 'Signature';
      }
      if (type === 'checkbox') {
        displayLabel = 'X';
      }

      let attrs = this.createSuperdocEditorAnnotationAttrs({
        type,
        displayLabel,
        fieldId,
        fieldType,
        fieldColor,
      });

      editor.commands.addFieldAnnotation(pos, attrs);
    },

   /**
    * Creates attrs object for superdoc editor annotation.
    * @param options.type The annotation type.
    * @param options.displayLabel The display label.
    * @param options.fieldId The field ID.
    * @param options.fieldType The field type.
    * @param options.fieldColor The field color.
    * @returns The attrs object.
    */
    createSuperdocEditorAnnotationAttrs({
      type = 'text',
      displayLabel,
      fieldId,
      fieldType,
      fieldColor = '#980043',
    }) {
      let requiredAttrs = [displayLabel, fieldId, fieldType];
      let isValid = requiredAttrs.every((attr) => !!attr);

      if (!isValid) {
        throw new Error('Required attributes are missing');
      }
      
      let attrs = {
        type,
        displayLabel,
        fieldId,
        fieldType,
        fieldColor,
      };

      return attrs;
    },

   /**
    * Updates attrs for superdoc editor annotations.
    * @param options.editor The editor.
    * @param options.fieldIdOrArray The field ID or array of field IDs.
    * @param options.attrs The attributes.
    */
    updateSuperdocEditorAnnotations({
      editor,
      fieldIdOrArray,
      attrs = {},
    }) {
      editor?.commands.updateFieldAnnotations(fieldIdOrArray, attrs);
    },

   /**
    * Deletes superdoc editor annotations.
    * @param options.editor The editor.
    * @param options.fieldIdOrArray The field ID or array of field IDs.
    */
    deleteSuperdocEditorAnnotations({
      editor,
      fieldIdOrArray,
    }) {
      editor?.commands.deleteFieldAnnotations(fieldIdOrArray);
    },

    /**
    * Set `highlighted` attr for annotations matching predicate.
    * @param options.editor The editor.
    * @param options.predicate The predicate function.
    * @param options.highlighted The highlighted attribute.
    */
    setSuperdocEditorAnnotationsHighlighted({
      editor,
      predicate = () => false,
      highlighted = true,
    }) {
      editor?.commands.setFieldAnnotationsHighlighted(predicate, highlighted);
    },

    /**
     * Preview SuperEditor annotations with input values.
     * @param options.editor The editor instance.
     * @param options.source The source (linkBuilder, signerLink or verificationsConsent).
     * @param options.props The additional props that may be required by a specific method.
     */
    previewSuperdocEditorAnnotations({ 
      editor,
      source,
      props = {},
    }) {
      let strategy = {
        linkBuilder: () => {
          this.previewEditorAnnotationsInLinkBuilder(editor, props);
        },
        signerLink: () => {
          this.previewEditorAnnotationsInSignerLink(editor, props);
        },
        verificationsConsent: () => {
          this.previewEditorAnnotationsInVerificationsConsent(editor, props);
        },
        default: () => {
          throw new Error('Not implemented.');
        },
      };

      let previewHandler = strategy[source] ?? strategy.default;

      previewHandler();
    },

    /**
     * Preview SuperEditor annotations with input values in Link Builder modal.
     * @param options.editor The editor instance.
     * @param options.props Contains additional props required by the method (eg. customInputs).
     */
    previewEditorAnnotationsInLinkBuilder(editor, props = {}) {
      let annotations = fieldAnnotationHelpers.getAllFieldAnnotations(editor.state);

      if (!annotations.length) {
        return;
      }

      let { customInputs = [] } = props;
      annotations.forEach((annotation) => {
        let { node } = annotation;
        let { fieldId, fieldType } = node.attrs;
        let newValue = null;

        let input = customInputs.find((input) => input.itemid === fieldId);

        if (!input) {
          let isCheckboxInput = (input) => input.itemfieldtype === 'CHECKBOXINPUT';
          let checkboxInputs = customInputs.filter(isCheckboxInput);
          
          for (let checkboxInput of checkboxInputs) {
            if (newValue) break;
            for (let option of checkboxInput.itemoptions) {
              if (option.itemid === fieldId) {
                newValue = checkboxInput.itemlinkvalue[option.itemid] || '&nbsp;&nbsp;';
                break;
              }
            }
          }
        }
        
        newValue = newValue || input?.itemlinkvalue || null;

        if (!newValue) {
          return;
        }

        let isImageField = fieldType === 'IMAGEINPUT';
        let isSignatureField = fieldType === 'SIGNATUREINPUT';
        let isHtmlField = fieldType === 'HTMLINPUT';
        let isUrlField = fieldType === 'URLTEXTINPUT'; // TODO: Double check.

        let isDateField = ['DATEINPUT', 'SIGNDATEINPUT'].includes(input?.itemfieldtype) &&
          input?.itemformat &&
          /^(\d{4})-(\d{1,2})-(\d{1,2})$/.test(newValue);
        
        if (isImageField || isSignatureField) {
          this.updateSuperdocEditorAnnotations({
            editor,
            fieldIdOrArray: fieldId,
            attrs: {
              imageSrc: newValue,
            },
          });
        } else if (isHtmlField) {
          this.updateSuperdocEditorAnnotations({
            editor,
            fieldIdOrArray: fieldId,
            attrs: {
              rawHtml: newValue,
            },
          });
        } else if (isDateField) {
          let [year, month, day] = newValue.split('-');
          let date = new Date(year, month - 1, day);
          newValue = date.toISOString();
          newValue = this.getFormattedDate(newValue, input.itemformat);
        } else {
          this.updateSuperdocEditorAnnotations({
            editor,
            fieldIdOrArray: fieldId,
            attrs: {
              displayLabel: newValue,
            },
          });
        }
      });
    },

    previewEditorAnnotationsInSignerLink(editor, props = {}) {
      throw new Error('Not implemented.');
    },

    previewEditorAnnotationsInVerificationsConsent(editor, props = {}) {
      throw new Error('Not implemented.');
    },

    getFormattedDate(val, format = '') {
      try {
        const date = new Date(val);
        const offsetDate = new Date(date.getTime());
        if (format) return dateFormat(offsetDate, format);

        const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(offsetDate);
        const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(offsetDate);
        const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(offsetDate);
        return `${mo} ${da} ${ye}`;
      } catch (e) {
        return val;
      }
    },

    /**
     * Opens the superdoc drafts modal from an uploaded .docx file.
     *
     * @param {{ file: File, parent: VueComponent }} options An object containing the file and parent component
     * @returns {Modal} The buefy modal instance
     */
        openSuperdocFromUpload({ file, parentComponent: parent, isBlankDocument }) {
          const props = {
            file,
            isBlankDocument,
            contextDict: this.harbourStore.getContextDict
          };
          const modal = Modal.open({
            component: SuperdocContainer,
            parent, 
            fullScreen: true,
            canCancel:  ['outside'],
            customClass: 'superdoc-modal',
            props,
            events: {},
            onCancel: () => {}
          });
    
          return modal;
        },
    
        /**
         * Open an existing draft in superdoc
         * 
         * @param {Object} draft The draft object
         * @param {VueComponent} parent The parent component
         * @returns {void}
         */
        async openSuperdocFromDrafts ({ name, id, parent, mode, template}) {
          const props = {
            superdocName: name,
            superdocId: id,
            contextDict: this.harbourStore.getContextDict,
            mode,
            template,
          };
          Modal.open({
            component: SuperdocContainer,
            parent, 
            fullScreen: true,
            canCancel:  ['outside'],
            customClass: 'superdoc-modal',
            props,
            events: {},
            onCancel: () => {}
          });
        },
  },
});

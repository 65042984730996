import Vue from 'vue';
import { MIN_DATE_FIELD_WIDTH, SIGNATURE_MIN_HEIGHT } from '@/config/magic-annotations.js';
import magicAnnotationsService from '@/services/magic-annotations-service.js';
import { useMagicAnnotationsStore } from '@/stores/magic-annotations-store.js';
import {
  getAttachmentFileSizeString,
  getAttachmentFileTypeFromName,
} from '@/utils/helpers/inline-attachments.js';

export default {
  /* ********************************************************************************************* */
  /* For processing real time events in folders */
  /* ********************************************************************************************* */

  parseRealtimeAction(data) {
    const source = data.source;
    if (source === this.contextDict.systemuserid) return;

    const actions = {
      sync_column_change: this.gridColumnChange,
      sync_folder_viewers: this.updateFolderViewers,
      sync_folders_ai_update: this.handleNewAiCell,
      sync_user_entry: this.handleManualEntry,
      sync_title_change: this.handleTitleChange,
      sync_asset_changed: this.handleAssetChange,
      sync_mage_suggestions: this.handleMagicAnnotationsChange,
      sync_cell_lock: this.handleCellLock,
    };

    const action = data.request_type;
    if (action in actions) actions[action](data);
  },

  handleMagicAnnotationsChange(data) {
    for (let [key, value] of Object.entries(data.data)) {
      const annotation = this.magicAnnotationsStore.suggestionsList.find(
        (item) => item.unique_box_id === key,
      );
      if (!annotation || annotation?.edited) continue;
      const annotationIndex = this.magicAnnotationsStore.suggestionsList.findIndex(
        (item) => item.unique_box_id === key,
      );

      const updatedCoordinates = this.magicAnnotationsStore.getSuggestionUpdatedCoordinates(
        annotation,
        value,
      );

      this.magicAnnotationsStore.suggestionsList = [
        ...this.magicAnnotationsStore.suggestionsList.slice(0, annotationIndex),
        {
          ...this.magicAnnotationsStore.suggestionsList[annotationIndex],
          ...value,
          ...updatedCoordinates,
        },
        ...this.magicAnnotationsStore.suggestionsList.slice(annotationIndex + 1),
      ];
      this.magicAnnotationsStore.pageMode = 'edit';
    }
  },

  handleAssetDeletion(id) {
    const index = this.myAssets.findIndex((asset) => asset.id === id);
    if (index !== -1) this.myAssets.splice(index, 1);
  },

  async handleAssetChange(data) {
    const { action, asset_id, folder_id, previous_folder } = data;

    // Handle asset deletion
    if (action === 'delete') return this.handleAssetDeletion(asset_id);

    if (action === 'move') {
      const index = this.myAssets.findIndex((asset) => asset.id === asset_id);
      if (index !== -1) this.myAssets.splice(index, 1);
    }

    const isMyFolder = this.myFolders.find((folder) => folder.id === folder_id);
    if (isMyFolder || this.isAdmin) {
      const options = {
        asset_request: {
          asset_id,
          folder_id,
        },
      };
      const result = await Vue.prototype.$harbourData.post('/folders/asset', options);
      if (!result.status === 200) return;

      // If the asset is already in the list, update and stop
      const existingAsset = this.myAssets.find((asset) => asset.id === asset_id);
      if (existingAsset) {
        const index = this.myAssets.findIndex((asset) => asset.id === asset_id);
        if (index !== -1) this.myAssets[index] = result.data;
        return;
      }

      const asset = result?.data;
      if (asset) {
        this.myAssets.unshift(asset);
        // Add inline attachments
        const { attachments } = asset;
        if (!attachments) {
          asset.attachments = [];
        }
        if (this.currentFolder === folder_id) this.libraryStore.gridApi?.refreshCells();
      }

      const existingTempAsset = this.myAssets.find((asset) => asset.newAssetId === asset_id);
      if (existingTempAsset) {
        const index = this.myAssets.findIndex((asset) => asset.id === existingTempAsset.id);
        if (index !== -1) this.myAssets.splice(index, 1);
      }
    }
  },

  handleTitleChange(data) {
    // Process a title change sync
    const asset = this.myAssets.find((asset) => asset.id === data.data.asset_id);
    if (!asset) return;

    asset.name = data.data.title;
    this.libraryStore.gridApi?.refreshCells();
    this.realtimeUserSync();
  },

  updateFolderViewers(data) {
    const folderId = data.folder_id;
    if (!folderId) return;

    const viewers = data.data;
    const email = this.contextDict.systememail;
    if (email in viewers) delete viewers[email];

    this.folderViewers = [];
    Object.keys(viewers).forEach((email) => {
      const viewer = viewers[email];
      viewer.folder_id = folderId;
      this.folderViewers.push(viewer);
    });

    this.updateFolderColors();
  },

  handleNewAiCell(data) {
    const asset = this.myAssets.find((asset) => asset.id === data.data.asset_id);
    if (!asset) return;

    const extractions = data.data.extraction;

    Object.keys(extractions).forEach((key) => {
      const assetId = data.data.asset_id;
      this.columnStore.removePendingExtraction(assetId, key);
      asset[key] = extractions[key];
    });

    const assetFolder = asset.folder_id;
    const node = this.libraryStore.gridApi?.getRowNode(asset.id);
    if (!node) return;

    console.debug('[AI] Updated asset:', data.data.asset_id, assetFolder, this.currentFolder);
    this.columnStore.gridApi?.redrawRows({ rowNodes: [node] });
  },

  updateFolderColors() {
    Object.keys(this.folderViewers).forEach((viewer) => {
      if (viewer in this.realtimeUserColors) return;

      const color = this.getRandomColor();
      this.realtimeUserColors[viewer] = color;
    });
  },

  gridColumnChange(data) {
    const { state } = data.data;
    const folder = this.getCurrentFolder;
    if (!folder) return;
    folder.columnState = state;

    // The data received here can contain information on what the change(s) were, such as a column deletion.
    this.columnStore.restoreColumns(data.data);
    this.realtimeUserSync();
  },

  // DATA ENTRY SECTION
  handleManualEntry(data) {
    const { reference_id, column } = data;
    const asset = this.myAssets.find((asset) => asset.id === reference_id);
    if (!asset) return;

    const value = data.data;
    asset[column] = value;

    if (column === 'notes') {
      this.libraryStore.updateNotesByAssetId(reference_id, {
        value,
        entry_timestamp: data.entry_timestamp,
        entry_user_name: data.entry_user_name,
        entry_user_picture: data.entry_user_picture,
        entry_user: data.entry_user,
      });
    }

    this.libraryStore.gridApi?.refreshCells();
    this.realtimeUserSync();
  },

  handleCellLock(request) {
    this.libraryStore.lockNotesByAssetId(request.data);
  },
};

import { authUtils } from '@/utils/auth-utils.js';
import { errorHandler } from '@/utils/helpers/error-handler.js';

export const endpointUrl = '/api/verifications';

export default {
  async listVerifications(payload = {}, params = {}) {
    try {
      const data = await authUtils.makeAuthenticatedRequest('get', endpointUrl, payload, params);
      return data?.items || [];
    } catch (err) {
      errorHandler('listVerifications', err);
      return [];
    }
  },

  async createVerification(payload = {}, params = {}) {
    try {
      return await authUtils.makeAuthenticatedRequest('post', endpointUrl, payload, params);
    } catch (err) {
      errorHandler('createVerification', err);
      throw err;
    }
  },

  async getVerification(verificationId, payload = {}, params = {}) {
    try {
      return await authUtils.makeAuthenticatedRequest(
        'get',
        `${endpointUrl}/${verificationId}`,
        payload,
        params,
      );
    } catch (err) {
      errorHandler('getVerification', err);
      throw err;
    }
  },

  async updateVerification(verificationId, payload = {}, params = {}) {
    try {
      return await authUtils.makeAuthenticatedRequest(
        'put',
        `${endpointUrl}/${verificationId}`,
        payload,
        params,
      );
    } catch (err) {
      errorHandler('updateVerification', err);
      throw err;
    }
  },

  async deleteVerification(verificationId, payload = {}, params = {}) {
    try {
      return await authUtils.makeAuthenticatedRequest(
        'delete',
        `${endpointUrl}/${verificationId}`,
        payload,
        params,
      );
    } catch (err) {
      errorHandler('deleteVerification', err);
      throw err;
    }
  },
};

import Vue from 'vue';

export default {
  async getAllFolders() {
    const res = await Vue.prototype.$harbourData.post('/data?filesystem-getallfolders', {
      requesttype: 'filesystem-getallfolders',
    });
    return res.data;
  },

  async getFolder(folderId) {
    const res = await Vue.prototype.$harbourData.get(`/api/folders/${folderId}`);
    return res.data;
  },

  async getCopyStatusByFolderId(folderId) {
    const res = await Vue.prototype.$harbourData.get(`/api/folders/${folderId}/copy-status`);
    return res.data;
  },

  async createNewFolder({ folderName, folderId }) {
    const res = await Vue.prototype.$harbourData.post('/data?filesystem-createnewfolder', {
      requesttype: 'filesystem-createnewfolder',
      newfoldername: folderName,
      currentfolderid: folderId,
    });
    return res.data;
  },

  async updateFolderName({ folderName, folderId }) {
    const res = await Vue.prototype.$harbourData.post('/data?filesystem-updatefoldername', {
      requesttype: 'filesystem-updatefoldername',
      newfoldername: folderName,
      currentfolderid: folderId,
    });
    return res.data;
  },

  async updateFolderParent({ targetFolderId, destinationFolderId, targetFolderOrigin }) {
    const res = await Vue.prototype.$harbourData.post('/data?filesystem-updatefolderparent', {
      requesttype: 'filesystem-updatefolderparent',
      target_folder_id: targetFolderId,
      destination_folder_id: destinationFolderId,
      target_folder_origin: targetFolderOrigin,
    });
    return res.data;
  },

  async removeFolder({ folderId, folderName, folderIds }) {
    const res = await Vue.prototype.$harbourData.post('/data?filesystem-removefoldercontent', {
      requesttype: 'filesystem-removefoldercontent',
      targetfolderid: folderId,
      targetfoldername: folderName,
      targetfolderids: folderIds,
    });
    return res.data;
  },

  async duplicateFolder({ folderId, newFolderName }) {
    const res = await Vue.prototype.$harbourData.post(`/api/folders/${folderId}/copy`, {
      folder_name: newFolderName,
    });

    return res.data;
  },

  async getFolderAssets({ folderId, options }) {
    const res = await Vue.prototype.$harbourData.post('/data?filesystem-getfolderassets', {
      requesttype: 'filesystem-getfolderassets',
      folderid: folderId,
      resultsconfig: options,
    });
    return res.data;
  },

  async getFolderAccessSettings({ folderId }) {
    const res = await Vue.prototype.$harbourData.post('/data?filesystem-getfolderaccesssettings', {
      requesttype: 'filesystem-getfolderaccesssettings',
      currentfolderid: folderId,
    });
    return res.data;
  },

  async startAssetRemoval({ assetDisplayId, assetDisplayPrefferedName, folderId }) {
    const res = await Vue.prototype.$harbourData.post('/data?filesystem-startassetremoval', {
      requesttype: 'filesystem-startassetremoval',
      assetdisplayid: assetDisplayId,
      assetdisplaypreferredname: assetDisplayPrefferedName,
      folderid: folderId,
    });
    return res.data;
  },

  async updateAssetDisplayName({ assetDisplayId, assetDisplayPrefferedName }) {
    const requestType = 'filesystem-updateassetpreferreddisplayname';
    const res = await Vue.prototype.$harbourData.post(`/data?${requestType}`, {
      requesttype: requestType,
      assetdisplayid: assetDisplayId,
      assetdisplaypreferredname: assetDisplayPrefferedName,
    });
    return res.data;
  },

  async getSignedAssetDataUrl({ assetDisplayId, isDownload, exportFileName }) {
    const res = await Vue.prototype.$harbourData.post('/data?uploadownload-getsigneddownloadurl', {
      requesttype: 'uploadownload-getsigneddownloadurl',
      assetdisplayid: assetDisplayId,
      isdownload: isDownload,
      exportfilename: exportFileName,
    });
    return res.data;
  },

  async getSignedFileDataUrl({ originalFileName, originalFileGcsPath, originalFileDisplayId }) {
    const res = await Vue.prototype.$harbourData.post('/data?filesystem-downloadoriginalfile', {
      requesttype: 'filesystem-downloadoriginalfile',
      originalfilename: originalFileName,
      originalfilegcspath: originalFileGcsPath,
      originalfiledisplayid: originalFileDisplayId,
    });
    return res.data;
  },

  async getAssetTags({ assetDisplayId }) {
    const res = await Vue.prototype.$harbourData.post('/data?filesystem-getassettags', {
      requesttype: 'filesystem-getassettags',
      targetdisplayid: assetDisplayId,
    });
    return res.data;
  },

  async getAssetDownloadZip({ assetDisplayIds, rootFolderName, includeAssets = true, includeAttachments = true}) {
    const res = await Vue.prototype.$harbourData.post('/data?filesystem-getassetdownloadzip', {
      requesttype: 'filesystem-getassetdownloadzip',
      assetdisplayidsarray: assetDisplayIds,
      includeassets: includeAssets,
      includeattachments: includeAttachments,
      rootfoldername: rootFolderName,
    });
    return res.data;
  },

  async getAssetDownloadCsv({ assetDisplayIds, folderId }) {
    const res = await Vue.prototype.$harbourData.post(
      '/export?filesystem-getassetdownloadcsv',
      {
        requesttype: 'filesystem-getassetdownloadcsv',
        assetdisplayidsarray: assetDisplayIds,
        folderid: folderId,
      },
      {},
      { responseType: 'blob' },
    );
    return res.data;
  },

  async getAssetDownloadCsvAll({ folderId }) {
    const data = await this.getAssetDownloadCsv({
      assetDisplayIds: [],
      folderid: folderId,
    });
    return data;
  },

  async getQrCodeJson({ fileName }) {
    const requestType = 'filesystem-getorganizationalsimplejsonstore';
    const res = await Vue.prototype.$harbourData.post(`/data?${requestType}`, {
      requesttype: requestType,
      filename: fileName,
    });
    return res.data;
  },

  async saveQrCodeJson({ fileName, qrJson }) {
    const requestType = 'filesystem-saveorganizationalsimplejsonstore';
    const res = await Vue.prototype.$harbourData.post(`/data?${requestType}`, {
      requesttype: requestType,
      filename: fileName,
      json_value: qrJson,
    });
    return res.data;
  },
};

import {
  DialogProgrammatic as Dialog,
  ModalProgrammatic as Modal,
  ToastProgrammatic as Toast,
} from 'buefy';
import { defineStore } from 'pinia';
import { v4 as uuidv4 } from 'uuid';
import Vue, { nextTick, ref } from 'vue';
import { useHarbourStore } from './harbour-store';

import { useSidebarStore } from '@/components/Sidebar/stores/sidebar-store';
import { useTemplatesStore } from '@/pages/Templates/stores/templates-store';
import { useWorkflow } from '@/pages/Workflows/composables/wf-use-workflow';
import { findMustacheVariables } from '@/pages/Workflows/helpers/helpers';

import HrbrEmailSendModal from '@/components/Modals/HrbrEmailSend.vue';
import WfMapTemplateFields from '@/pages/Workflows/components/WfMapTemplateFields.vue';
import WorkflowsApiService from '@/services/workflows-api-service';
import WorkflowsBlockStageFunctions from './workflows-block-stage';

export const useWorkflowsStore = defineStore('workflows', {
  state: () => ({
    harbourStore: useHarbourStore(),
    sidebarStore: useSidebarStore(),
    templatesStore: useTemplatesStore(),
    debugMode: false,
    hasInitialized: false,

    // Possible block definitions for the stage
    latestBlockDefs: {
      // startAgreement: {}, // Commented out for now until completion of non-start block logic
      sendEmail: {},
      sendEmailForSignature: {},
      documentCompleted: {},
      requestApproval: {},
      documentReadyForSignature: {},
      writeCode: {},
      saveFile: {},
      conditionalPath: {},
    },

    // Workflows menu grid apis
    sidebarGridApi: null,
    sidebarGridColumnApi: null,
    groupNodeState: {},

    // Main state
    workflowGroups: [],
    workflows: [],
    mappings: [],
    workflowBlocks: [],
    blocksByRow: [],
    stageWidth: 0,
    leftmostX: 0,
    rightmostX: 0,
    padding: 20,
    blockSize: 320,
    paths: [],
    pathsContainer: null,
    stage: null,
    possibleOutcomes: [],
    blockCurrentlyDragging: null,
    isCalculatingPositions: false,
    isEditingApprovalFlowLink: false,

    // Loading
    isWorkflowsListReady: false,
    isCreatingGroup: false,

    // Main view state
    activeWorkflowGroup: null,
    activeWorkflow: null,
    viewFilterText: null,
    activeBlock: null,
    callingBlock: null,
    initialBlockClick: false,
    stageScale: 1,
    nodeStage: null,
    stageLocation: { x: 0, y: 0 },

    // Add block menu
    isAddMenuOpen: false,
    outcomeClicked: null,
    outcomeElementClicked: null,
    workflowsActiveEleent: null,
    clickPosition: { x: null, y: null },

    // Right sidebar info
    sidebarTitle: 'Workflow settings',
    isActionsMenuOpen: false,

    // Workflow builder current flow
    attachedTemplates: [],
    sampleTemplateInputs: [],
    sampleTemplate: null,

    // Workflow mappings
    mapping: [],
    isInitialLoading: true,
  }),
  getters: {
    isApprovalWorkflow: (state) => (snapshot) => {
      if (!snapshot || !snapshot.blocks) return false;
      return snapshot.blocks?.some((block) => block.key === 'requestApproval');
    },
    isWorkflowCompleted: (state) => (wf) => {
      return wf.status.state === 'completed';
    },
    isWorkflowApproved: (state) => (snapshot) => {
      if (!snapshot || !snapshot.blocks) return false;
      const approvalBlocks = snapshot?.blocks?.filter((block) => block.key === 'requestApproval');
      if (!approvalBlocks || approvalBlocks.length < 1) return false;
      const lastApproval = approvalBlocks.pop();
      return lastApproval.state?.stage === 'approved';
    },
    isWorkflowFailed: (state) => (wf) => {
      return wf.status.state === 'error';
    },
    isWaitingOnMe: (state) => (snapshot) => {
      const currentUserEmail = state.harbourStore.contextDict?.systememail;
      const aprovalBlocks = snapshot?.blocks?.filter((block) => block.key === 'requestApproval');
      if (!aprovalBlocks) return false;

      const pendingBlock = aprovalBlocks.find((block) => block.state.state === 'wait');
      if (!pendingBlock) return false;

      const user = pendingBlock.state?.details?.creator_email;
      if (!user) return false;
      const creatorUser = {
        email: pendingBlock.state?.details?.creator_email,
        name: pendingBlock.state?.details?.creator_name,
      };

      const users =
        pendingBlock?.state?.stage === 'sent_back'
          ? [creatorUser]
          : pendingBlock.state?.details?.approvers;
      if (!users || users.length === 0) return false;

      const isPending = users.some((user) => user.email === currentUserEmail);
      return isPending;
    },
    myWorkflows: (state) => {
      const result = state.workflows.filter((workflow) => {
        return (
          (workflow.creator === state.harbourStore.contextDict?.systememail ||
          workflow.creator_email === state.harbourStore.contextDict?.systememail || workflow.isOrgWide)
        );
      });
      return result;
    },
    workflowsByTemplateId: (state) => (templateId) => {
      const workflowIds = state.mappings
        .filter((mapping) => mapping.object_id === templateId && mapping.object_type === 'template')
        .map((mapping) => mapping.workflow_id);
      const result = state.myWorkflows.filter((workflow) => workflowIds.includes(workflow.id));
      return result;
    },
    actionBlocks: (state) => {
      const nonEventLabelBlocks = [];
      for (const key in state.latestBlockDefs) {
        if (state.latestBlockDefs[key].isEventLabel) continue;
        nonEventLabelBlocks.push(key);
      }
      return nonEventLabelBlocks;
    }
  },
  actions: {
    ...WorkflowsBlockStageFunctions,

    async initializeWorkflows() {
      if (this.hasInitialized) return;
      this.hasInitialized = true;
      this.loadLatestBlockDefinitions();

      const promises = [
        this.processServerWorkflows(),
        this.processWorkflowGroups(),
        this.processServerMappings(),
      ];
      try {
        await Promise.all(promises);
      } catch (err) {
        console.error(err);
      }
      if (this.workflows?.length > 0) this.restoreFromServer();
      this.isWorkflowsListReady = true;
    },

    async processWorkflowGroups() {
      const results = await WorkflowsApiService.getWorkflowGroups();
      this.workflowGroups = results;
    },

    async processServerMappings() {
      const results = await WorkflowsApiService.getWorkflowMappings();
      this.mappings = results;
    },

    async processServerWorkflows() {
      const result = await WorkflowsApiService.getWorkflows();
      const workflows = [];

      for (const workflow of result) {
        const wf = useWorkflow(workflow);
        const result = await wf.loadBlocks();
        if (!result) continue;

        workflows.push(wf);
      }
      this.workflows = workflows;
    },

    restoreFromServer() {
      this.activeWorkflow = this.myWorkflows[0];
      this.refreshStage();
    },

    async firstTimeStarting(name, startingTemplate = null) {
      this.isInitialLoading = false;
      const wf = this.createNewWorkflow(name);
      await wf.initNewWorkflow(['startAgreement'], this.workflowGroups[0].id);
      this.workflows = [wf];
      this.activeWorkflow = wf;
      await this.quickStartApprovalFlow({ wf, params: wf.blocks[0] });
      this.loadLatestBlockDefinitions();

      // If we arrived here with a startingTemplate, add it to the new workflow
      if (startingTemplate) {
        const startBlock = wf.blocks[0];
        startBlock.actions.loadTemplateIntoBlock(startBlock, startingTemplate);
        startBlock.internalState.hasPromptedForTemplate = true;
      }

      this.sidebarGridApi?.refreshCells();
    },

    async quickStartApprovalFlow({ wf = null, params = null }) {
      if (!wf) wf = this.activeWorkflow;

      const source = 'Auto-generate quick approval flow';
      const outcome = params.outcomes[0];
      const requestBlock = await wf.insertBlock('requestApproval', outcome, source);

      await new Promise((resolve) => setTimeout(resolve, 250));
      await wf.insertBlock('sendEmailForSignature', requestBlock.outcomes[0], source);
      await new Promise((resolve) => setTimeout(resolve, 250));
      await wf.insertBlock('sendEmail', requestBlock.outcomes[1], source);
      await new Promise((resolve) => setTimeout(resolve, 5));

      await wf.rearrangeEventLabels();
      this.setActiveBlock(requestBlock);
      this.refreshStage();

    },

    saveCurrentWorkflow(saveParams) {
      this.activeWorkflow.save(saveParams);
    },

    validateTemplateHasExistingApproval(agreement, currentWorkflow) {
      const connectionsForTemplate = this.mappings.filter(
        (map) => map.object_id === agreement.agreement_id,
      );

      let alreadyHasApproval = false;
      connectionsForTemplate.forEach((conn) => {
        const workflow = this.workflows.find((wf) => wf.id === conn.workflow_id);
        if (!workflow || workflow.id === currentWorkflow.id) return;

        const hasApprovals = workflow.checkForApprovals();
        if (hasApprovals) alreadyHasApproval = true;
      });
      return alreadyHasApproval;
    },

    createSampleTemplateConnection(wf) {
      const startBlock = wf.blocks[0];
      const template = startBlock.internalState.linkedAgreement;

      // If no sample template, proceed with the publisher without any connection here
      if (!template && !wf.isOrgWide) {
        return true;
      }
      // Org-wide workflows aren't linked to a template
      // So we can create a connection without a template
      if (!template && wf.isOrgWide) {
        wf.linkWorkflowToObject(startBlock.variables.triggerType.value, '', {});
        return;
      }

      // Check if this workflow is already in the template
      // If so we can return early - will not be creating a connection
      const hasExistingConnection = this.mappings.find(
        (map) => map.workflow_id === wf.id && map.agreement_id === template.agreement_id,
      );

      if (hasExistingConnection) return true;

      // Check all flows in this template for approvals:
      const templateHasApproval = this.validateTemplateHasExistingApproval(template, wf);

      // Does this workflow have approvals?
      const wfHasApprovals = wf.checkForApprovals();

      // If the template has approval, and this new flow has approval, we can't proceed
      if (templateHasApproval && wfHasApprovals) {
        return false;
      }

      // If we already have this workflow connected, no need for a new map
      const workflowAlreadyConnected = this.mappings.find(
        (map) => map.workflow_id === wf.id && map.object_id === template.agreement_id,
      );
      if (!workflowAlreadyConnected) {
        // Create the connection
        this.mappings.push({
          workflow_id: wf.id,
          workflow_name: wf.name,
          object_id: template?.agreement_id,
          mapping: { mapping: [] },
        });
      }

      // If we made it this far, then this is a valid sample connection and we can create the connection
      const agreement_id = template.agreement_id;
      wf.linkWorkflowToObject('template', agreement_id, {});

      return template;
    },

    publishCurrentWorkflow() {
      const wf = this.activeWorkflow;

      // Validate if we had a sample template
      const sampleTemplate = this.createSampleTemplateConnection(wf);

      // Publish the workflow
      wf?.publish(this.activeWorkflow);

      // If this is false, then a new connection was not made - different message
      if (sampleTemplate === false) {
        Toast.open({
          duration: 3000,
          message:
            'Workflow has been published but not connected to the template (already has an existing approval workflow)',
          type: 'is-warning',
        });
      } else {
        // Regular success message
        Toast.open({
          duration: 3000,
          message: `${this.activeWorkflow.name} has been published.`,
          type: 'is-success',
        });
      }
    },

    async loadLatestBlockDefinitions() {
      for (const key in this.latestBlockDefs) {
        const def = await useWorkflow().getBlockDefinition(key);
        if (def) this.latestBlockDefs[key] = def;
        else delete this.latestBlockDefs[key];
      }
    },

    getEmailListVars(block, variableName) {
      const requiredFields = [];

      // Check the recipients list
      const recipientsList = block.variables[variableName];
      const recipientsListValue = recipientsList?.value;
      recipientsListValue.forEach((recipient, index) => {
        // If there's an empty recipient email, let the user map it at template map time
        if (!recipient.email) {
          requiredFields.push({
            index,
            name: recipientsList.name,
            type: 'email',
            placeholder: 'Enter email...',
            variable: variableName,
          });
        } else if (recipient.email.startsWith('{{') && recipient.email.endsWith('}}')) {
          requiredFields.push({
            index,
            name: recipientsList.name,
            type: 'email',
            placeholder: recipient.email,
            variable: variableName,
          });
        }
      });

      return requiredFields;
    },

    findMustacheTemplates(inputString) {
      const regex = /\{\{[^{}]+\}\}/g;
      return inputString.match(regex);
    },

    getOutcomesInRow(level) {
      const blocks = this.activeWorkflow.blocks[level];
      const outcomes = [];
      blocks.forEach((block) => {
        block.outcomes.forEach((outcome) => {
          outcomes.push(outcome);
        });
      });
      return outcomes;
    },

    getNumberOfElementsInRow(level) {
      const blocks = this.activeWorkflow.blocks[level];
      let count = 0;
      blocks.forEach((block) => {
        count += block.outcomes.length;
      });
      return count;
    },

    addSpacerBlock(level) {
      const standardBlock = { ...this.standardBlock };
      return { ...standardBlock, isSpacer: true, level };
    },

    setActiveWorkflow(workflow) {
      this.activeBlock = null;
      this.activeWorkflow = workflow;
      this.activeWorkflow.blocks = workflow.blocks;
      this.refreshStage();
    },

    createNewWorkflowGroup(groupName) {
      return {
        name: groupName.trim(),
        icon: 'fal code-branch',
      };
    },

    async createNewWorkflowGroupServer(group) {
      const result = await Vue.prototype.$harbourData.post('/api/workflows_groups', group);
      if (result.status === 201) return result.data;
      else {
        // TODO: Error handling
      }
    },

    async addNewWorkflowGroup(groupName) {
      this.isCreatingGroup = true;

      const tempId = `temp-${uuidv4()}`;
      const group = this.createNewWorkflowGroup(groupName);
      group.id = tempId;
      this.workflowGroups.push(group);

      // Replace the temp group with the new server-generated group
      const createdGroup = await this.createNewWorkflowGroupServer(group);
      this.workflowGroups = this.workflowGroups.map((group) => {
        if (group.id === tempId) return createdGroup;
        return group;
      });

      this.isCreatingGroup = false;
      return group;
    },

    getActiveWorkflowGroup() {
      const id = this.activeWorkflowGroup;
      return this.workflowGroups.find((group) => group.id === id);
    },

    async _duplicateWorkflow(outcomes, originalBlock, blocks, wf) {
      const startBlockId = wf.blocks.value[0].id;

      for (const outcome of outcomes) {
        const outcomeIndex = outcome.outcomeId;
        const originalOutcome = originalBlock.outcomes.find((o) => o.outcomeId === outcomeIndex);
        const nextBlock = blocks.find((b) => b.id === originalOutcome.childId);
        if (!nextBlock) continue;

        const newBlock = await wf.insertBlock(
          nextBlock.key,
          outcome,
          'duplicated workflow',
          true,
          true,
        );
        newBlock.variables = { ...nextBlock.variables };

        if (newBlock.key === 'requestApproval') {
          newBlock.variables['blocks-to-approve'].value = [startBlockId];
        } else if (newBlock.key === 'sendEmailForSignature') {
          newBlock.variables['blocks-to-include'].value = [startBlockId];
        }
        newBlock.generalSettings = { ...nextBlock.generalSettings };
        newBlock.overflowMenu = { ...nextBlock.overflowMenu };
        const nextOutcomes = [...newBlock.outcomes];
        await this._duplicateWorkflow(nextOutcomes, nextBlock, blocks, wf);
      }
      return wf;
    },

    async duplicateWorkflow(workflow, name) {
      this.isWorkflowsListReady = false;
      const flow = workflow.generateSaveObject();
      flow.id = null;
      flow.name = name;
      flow.publish_state = false;
      flow.group = workflow.group;

      const blocks = workflow.blocks;
      flow.blocks = [];
      const wf = useWorkflow(flow);
      wf.creator = this.harbourStore.contextDict?.systememail;
      wf.creatorName = this.harbourStore.contextDict?.name;

      // Initialize the root block
      const firstBlock = blocks[0];
      await wf.initNewWorkflow([firstBlock.key], workflow.group);
      wf.blocks.value[0].variables = { ...firstBlock.variables };
      wf.blocks.value[0].generalSettings = { ...firstBlock.generalSettings };

      const newBlock = wf.blocks.value[0];
      const initialOutcomes = newBlock.outcomes;
      await this._duplicateWorkflow(initialOutcomes, firstBlock, blocks, wf);
      this.workflows.push(wf);
      this.isWorkflowsListReady = true;
    },

    async addNewWorkflow({ workflowName = null, group = null, isOrgWide = false }) {
      this.activeWorkflow = null;
      this.activeBlock = null;

      this.isWorkflowsListReady = false;
      const wf = this.createNewWorkflow(workflowName, isOrgWide);
      const defaultGroup = this.workflowGroups.find((g) => g.name === 'My workflows');

      if (!group) {
        if (this.activeWorkflowGroup) group = this.getActiveWorkflowGroup();
        else if (defaultGroup)
          group = this.workflowGroups.find((group) => group.name === 'My workflows');
        else group = this.workflowGroups[0];
      }

      await wf.initNewWorkflow(['startAgreement'], group.id);
      wf.blocks.value.forEach(block => block.isFromOrgWideGroup = isOrgWide);
      this.workflows.push({...wf, isOrgWide});
      this.activeWorkflow = wf;

      this.isWorkflowsListReady = true;
      this.refreshStage();
      return wf;
    },

    addWorkflowFromPrompt(callback, isOrgWideGroup = false) {
      Dialog.prompt({
        title: 'New workflow',
        message: 'Enter a name for your new workflow',
        confirmText: 'Create',
        cancelText: 'Cancel',
        onConfirm: async (newName) => {
          await this.addNewWorkflow({ workflowName: newName, isOrgWide: isOrgWideGroup });
          callback && callback();
        },
        onCancel: () => {
          this.isWorkflowsListReady = true;
        },
      });
    },

    async renameWorkflowGroup(groupId, newName) {
      const group = this.workflowGroups.find((group) => group.id === groupId);
      const oldName = group.name;
      group.name = newName;

      const result = await Vue.prototype.$harbourData.put(`/api/workflows_groups/${groupId}`, {
        name: newName,
      });
      if (result.status === 200) return;
      else {
        group.name = oldName;
      }
    },

    getWorkflowsForUser() {
      return this.workflows.filter((wf) => wf.creator === this.harbourStore.contextDict?.systememail);
    },

    resetActiveWorkflow() {
      const userWorkflows = this.getWorkflowsForUser();
      this.activeWorkflow = userWorkflows.length ? this.workflows[0] : null;
      this.activeBlock = null;
      this.workflowBlocks = [];
      this.blocksByRow = [];
      this.sidebarGridApi?.refreshCells();
      this.refreshStage();
    },

    async deleteWorkflowGroup(groupId) {
      if (this.workflowGroups.length === 1) {
        Toast.open({
          duration: 2500,
          message: 'Unable to remove the last workflow group',
          type: 'is-danger',
        });
        return;
      }

      this.resetActiveWorkflow();

      const workflowsInGroup = this.workflows.filter((wf) => wf.group === groupId) || [];
      workflowsInGroup.forEach((wf) => {
        this.mappings = this.mappings.filter((m) => m.workflow_id !== wf.id);
      });

      const oldGroup = this.workflowGroups.find((group) => group.id === groupId);
      this.workflowGroups = this.workflowGroups.filter((group) => group.id !== groupId);
      const result = await Vue.prototype.$harbourData.delete(`/api/workflows_groups/${groupId}`);
      if (result.status !== 202) return this.workflowGroups.push(oldGroup);
    },

    createNewWorkflow(name = null, isOrgWide = false) {
      const wf = useWorkflow({ name, is_org_wide: isOrgWide });
      wf.creator = this.harbourStore.contextDict?.systememail;
      wf.creatorName = this.harbourStore.contextDict?.name;
      return wf;
    },

    handleWorkflowsViewFilter(text) {
      if (!this.isActionsMenuOpen) this.isActionsMenuOpen = true;
      this.viewFilterText = text;
    },

    openFieldMapperModal(template) {
      Modal.open({
        title: 'Map required fields',
        parent: Vue.$root,
        component: WfMapTemplateFields,
        props: {
          template,
        },
        hasModalCard: true,
        trapFocus: true,
      });
    },

    openPublishedModal(parent, workflow) {},

    removeBlockFromMapping(id) {
      this.mapping = this.mapping.filter((item) => item.blockId !== id);
    },

    removeBlock(id) {
      const blockToRemove = this.activeWorkflow?.blocks.find((block) => block.id === id);
      if (!blockToRemove || blockToRemove?.isRoot) return;

      this.removeBlockFromMapping(id);
      const children = blockToRemove.outcomes.filter((outcome) => outcome.child);
      const possibleOutcomes = blockToRemove.outcomes;

      const parentBlock = blockToRemove.parent;
      if (!parentBlock) return;

      this.activeBlock = null;

      // If no children, we can remove the block safely
      if (!children.length) {
        const outcome = parentBlock.outcomes.find(
          (outcome) => outcome.childId === blockToRemove.id,
        );
        this.activeWorkflow.blocks = this.activeWorkflow.blocks.filter(
          (block) => block.id !== blockToRemove.id,
        );
        outcome.child = null;
      }

      // If we have one child but also the parent only has one outcome, we can also remove the block safely
      else if (possibleOutcomes.length === 1) {
        const outcome = parentBlock.outcomes.find(
          (outcome) => outcome.childId === blockToRemove.id,
        );
        const blockChild = blockToRemove.outcomes[0].child;
        blockChild.setParent(ref(parentBlock));
        parentBlock.setOutcome(outcome.outcomeIndex, blockChild);

        this.activeWorkflow.blocks = this.activeWorkflow.blocks.filter(
          (block) => block.id !== blockToRemove.id,
        );
      } else if (possibleOutcomes.length > 1) {
        Dialog.confirm({
          title: 'Deleting block',
          message: 'This will remove all blocks under this block',
          confirmText: 'Delete',
          type: 'is-danger',
          onConfirm: async () => {
            // Traverse the tree and remove all children
            const outcome = parentBlock.outcomes.find(
              (outcome) => outcome.childId === blockToRemove.id,
            );
            parentBlock.setOutcome(outcome.outcomeIndex, null);
            const ids = this.treeTraverseGetIds(blockToRemove);
            this.activeWorkflow.blocks = this.activeWorkflow.blocks.filter(
              (block) => !ids.includes(block.id),
            );
            await this.activeWorkflow.rearrangeEventLabels();
            this.refreshStage();
          },
        });
      }

      this.activeBlock = null;
      this.refreshStage();

      const saveParams = {
        source: 'Remove block',
        change_note: `Removed ${blockToRemove.title} block`,
        current_change: blockToRemove.save(),
        block_id: blockToRemove.id,
      };
      this.activeWorkflow.save(saveParams);
    },

    treeTraverseGetIds(node, nodeIds = []) {
      if (node === null) {
        return;
      }

      nodeIds.push(node.id);
      node.outcomes.forEach((outcome) => {
        this.treeTraverseGetIds(outcome.child, nodeIds);
      });

      return nodeIds;
    },

    setActiveBlock(block) {
      this.activeBlock = null;

      nextTick(() => {
        this.activeBlock = block;
        this.sidebarTitle = block?.title;
        block?.activate();
      });
    },

    handleBlockDragEnd() {
      this.isBlockDragging = false;
      this.possibleOutcomes.forEach((outcome) => {
        outcome.ref.setDropzoneState(false);
      });
    },

    async handleBlockDropAction(key, isDemo, id, outcome) {
      if (isDemo && key) {
        await this.activeWorkflow.insertBlock(key, outcome, 'Action block drag and drop');
      } else {
        await this.activeWorkflow.reorderBlock(id, outcome, 'Re-ordered dragged block');
      }

      await this.activeWorkflow.rearrangeEventLabels();
  
      this.refreshStage();
    },

    _test_moustache(str) {
      const variableRegex = /{{\s*([^\s}]+)\s*}}/g;
      const variables = [];
      let match;
      while ((match = variableRegex.exec(str)) !== null) {
        const variableName = match[1].trim();
        if (!variables.includes(variableName)) {
          variables.push(variableName);
        }
      }
      return variables;
    },

    getBlockPath(mustache) {
      const variable = mustache.replace(/{{\s*|\s*}}/g, '')?.trim();
      const path = variable.split('.');
      return path;
    },

    getVariableData(block, variable) {
      const data = block.getUpstreamData();
      const blockPath = this.getBlockPath(variable);
      const sourceBlock = data.find((block) => block.friendlyId === blockPath[0]);

      if (!sourceBlock) return null;
      const availableInputs = sourceBlock.actions.getAvailableInputs(sourceBlock);

      const matchedInput = availableInputs.find((input) => input.variableName === variable);
      return matchedInput;
    },

    getVarsToRemap(text, requiredRemapNames) {
      const seenVars = new Set();
      const variablesInText = findMustacheVariables(text);
      const requiredVars = [];
      for (const variable of variablesInText) {
        if (seenVars.has(variable)) continue;
        seenVars.add(variable);
        if (requiredRemapNames.some((n) => variable.startsWith(n))) {
          requiredVars.push(variable);
        }
      }
      return requiredVars;
    },

    hasRequiredVariable(text, requiredRemapNames) {
      return requiredRemapNames.some((n) => text.startsWith(n));
    },

    getRequiredVars(workflow, template) {
      const blocks = workflow.blocks;
      const missingVarsList = [];
      const seenVarIds = new Set();

      const requiredRemapNames = [
        '{{ startAgreement1.signers',
        '{{ startAgreement1.agreementOwnerInputs',
      ];

      // Only variables attached from the start agreement block can change when attaching a different template.
      // Iterate through all blocks of the workflow and find variables that need re-mapping
      // Some blocks or modules have special treatment
      blocks.forEach((block) => {
        const modules = block.moduleSettingsList;

        modules.forEach((module) => {
          const allowedModules = ['WfEmailList', 'WfTextEditor', 'WfConditionalPathSettings'];

          // We don't allow customization of the email for signature block email list
          if (block.key === 'sendEmailForSignature' && module.component === 'WfEmailList') return;

          // If its not one of our allowed modules, skip
          if (!allowedModules.includes(module.component)) return;
          const linkedVar = block.variables[module.params.variableSource];
          if (Array.isArray(linkedVar.value)) {
            // Special case for conditional block, since the linked variables are nested
            // TODO: Figure out a better way to handle this to remove duplicated code
            if (module.component === 'WfConditionalPathSettings') {
              linkedVar.value.forEach((item) => {
                item.conditions.forEach((condition) => {
                  if (!('link' in condition)) return;

                  const variableLink = condition['link'];
                  if (!variableLink) return;
                  if (this.hasRequiredVariable(variableLink.variableName, requiredRemapNames)) {
                    if (seenVarIds.has(variableLink.variableName)) return;
                    missingVarsList.push(variableLink);
                    seenVarIds.add(variableLink.variableName);
                  }
                });
              });
            } else {
              linkedVar.value.forEach((item, index) => {
                if (!('link' in item)) return;

                const variableLink = item['link'];
                if (!variableLink) return;
                if (this.hasRequiredVariable(variableLink.variableName, requiredRemapNames)) {
                  if (seenVarIds.has(variableLink.variableName)) return;
                  missingVarsList.push(variableLink);
                  seenVarIds.add(variableLink.variableName);
                }
              });
            }
          } else if (typeof linkedVar.value === 'object') {
            for (const key in linkedVar.value) {
              const value = linkedVar.value[key];
              const varsToRemap = this.getVarsToRemap(value, requiredRemapNames);
              varsToRemap.forEach((varToRemap) => {
                const data = this.getVariableData(block, varToRemap);
                if (data) {
                  if (data.templateId === template.agreement_id) return;
                  if (seenVarIds.has(varToRemap.variableName)) return;
                  missingVarsList.push(data);
                  seenVarIds.add(data.variableName);
                }
              });
            }
          }
        });
      });
      return [...missingVarsList];
    },

    generateUserVariable(userInfo) {
      return {
        userInfo,
        value: userInfo.value,
        variableName: `{{ users.${userInfo.value} }}`,
        blockTitle: 'Users',
        grouping: 'Users',
        icon: 'fas fa-user',
      };
    },

    sendNudge(subject, message, link, workflowData, emailInputData) {
      Modal.open({
        parent: Vue.$root,
        component: HrbrEmailSendModal,
        hasModalCard: true,
        customClass: 'modalCustomClassStickToTop',
        props: {
          workflowdata: workflowData,
          requestemailtype: 'WF_PENDING_APPROVER',
          requesttitle: subject,
          requestmessage: message,
          toemailsarray: [workflowData.recipient_email],
          ccemailsarray: [],
          emailInputData: emailInputData,
          agreelinkdisplayid: link.id,
          agreementtitle: link.title,
        },
      });
    },

    async redirectToMyLink(status) {
      const workflowData = {
        block_id: status.status?.details?.block_id,
        workflow_id: status.workflow_id,
        run_id: status.run_id,
      };

      const payload = {
        redirect_data: {
          ...workflowData,
        },
      };

      const url = '/api/workflows/redirect-to-approval';
      const result = await Vue.prototype.$harbourData.post(url, payload);
      if (result.data.link) {
        window.open(result.data.link, '_blank');
      }
    },
  },
});

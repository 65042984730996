import Vue from 'vue';

export default {
  async getAllTemplates(lastRequestDateTimeEpochMS) {
    const res = await Vue.prototype.$harbourData.post('data?new_agreement_templates_gallery', {
      requesttype: 'new_agreement_templates_gallery',
      lastrequestdatetimeepochms: lastRequestDateTimeEpochMS,
    });
    return res.data;
  },
  async getTemplatesGroups(parameters = {}) {
    const res = await Vue.prototype.$harbourData.post('data?agreement_editor_get_templates_groups', {
      requesttype: 'agreement_editor_get_templates_groups',
      ...parameters,
    });
    return res.data;
  },
  async updateTemplateGroup(templateGroup){
    await Vue.prototype.$harbourData.post('data?update_template_group', {
      requesttype: 'update_template_group',
      template_group_name: templateGroup.groupName,
      template_group_id: templateGroup.groupId,
      template_group_user_groups: templateGroup.groupUsersList,
    });
  },
  async deactivateTemplate(templateId) {
    const res = await Vue.prototype.$harbourData.post('data?agreement_editor_deactivate_agreement', {
      requesttype: 'agreement_editor_deactivate_agreement',
      templateid: templateId,
    });

    return res.data;
  },
  async deactivateTemplateGroup(templateGroupId) {
    const res = await Vue.prototype.$harbourData.post('data?deactivate_template_group', {
      requesttype: 'deactivate_template_group',
      templategroupid: templateGroupId,
    });

    return res.data;
  },
  async getPdfPreview(templateId, templateCustomInputsEligible) {
    const res = await Vue.prototype.$harbourData.post('/data?agreebuilder-renderpdfpreview', {
          requesttype: 'agreebuilder-renderpdfpreview',
          agreementid: templateId,
          linkdisplayid: null,
          custominputs: {
            custominputs: templateCustomInputsEligible,
          },
          annotationrenderstage: 'LINKBUILDERPREVIEWONLY',
          contentsource: null,
          isvisualdebugmode: false,
          linkcreatedatetimeepochms: null, // *Null when link yet created
          onlypdfbase64: true,
        })
    return res.data;
  },

  async copyAgreementTemplateRequest({ templateId, fileDisplayId, isTemplate, addCopyToTitle }) {
    const requestParams = {
      agreementid: templateId,
      istemplate: isTemplate
    };
    if (fileDisplayId) requestParams.filedisplayid = fileDisplayId;
    if (addCopyToTitle != null) requestParams.addcopytotitle = addCopyToTitle;

    const res = await Vue.prototype.$harbourData.post('data?agreement_editor_copy_agreement_template', {
      requesttype: 'agreement_editor_copy_agreement_template',
      ...requestParams
    });
    return res.data;
  },
  async getRecentUsedTemplates(recentTemplatesLastRequestEpochMS) {
    const resp = await Vue.prototype.$harbourData.post('/data?get_recent_created_used_templates', {
      requesttype: 'get_recent_created_used_templates',
      lastrequestdatetimeepochms: recentTemplatesLastRequestEpochMS,
    });
    return resp.data;
  },
  async createTemplateGroup(templateGroup){
    const { data } = await Vue.prototype.$harbourData.post('data?create_template_group', {
      requesttype: 'create_template_group',
      template_group_name: templateGroup.groupName,
      template_group_user_groups: templateGroup.groupUsersList,
    });
    return data;
  }
};

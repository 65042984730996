<script setup>
import ArrowRight from '@/assets/icons/arrow-right.svg?raw';
import hrbrSwitch from '@/components/ui/HrbrSwitch.vue';
import { useSettingsPageStore } from '@/pages/Settings/stores/settings-store';
import WfVariablePill from '@/pages/Workflows/components/settings-modules/WfVariablePill.vue';
import { useWorkflowsStore } from '@/stores/workflows-store';
import { ToastProgrammatic as Toast } from 'buefy';
import { _ } from 'lodash';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref } from 'vue';
import WfVariableMapperField from './WfVariableMapperField.vue';

const workflowsStore = useWorkflowsStore();
const { workflowGroups, workflows, mappings } = storeToRefs(workflowsStore);
const { getRequiredVars } = workflowsStore;
const settingsStore = useSettingsPageStore();
const { listUserGroups } = storeToRefs(settingsStore);

const props = defineProps({
  template: {
    type: Object,
    required: true,
  },
  workflow: {
    type: Object,
    required: false,
  },
  isSelected: {
    type: Boolean,
    required: false,
  },
  hasApproval: {
    type: String,
    required: false,
  },
  checkForApprovals: {
    type: Function,
    required: false,
  },
});

const emit = defineEmits(['toggle', 'field-map-update']);
const getGroupOrCreator = computed(() => {
  const groupName = workflowGroups.value.find((group) => group.id === props.workflow.group)?.name;
  if (groupName) return groupName;
  return props.workflow.creator;
});

const getCurrentMapping = computed(() => {
  if (!props.workflow) return;
  const currentMap = mappings.value.find((map) => {
    return map.workflow_id === props.workflow.id && map.object_id === props.template.agreement_id;
  });

  return currentMap?.mapping?.mapping || [];
});

const selected = ref(props.isSelected || false);
const switchClickHandler = () => {
  if (props.workflow.checkForApprovals() && !selected.value && props.checkForApprovals()) {
    Toast.open({
      message: 'Template already has an active approval workflow.',
      type: 'is-danger',
    })
    return;
  }
  const wf = workflows.value.find((wf) => wf.id === props.workflow.id);
  const startBlock = wf.blocks[0];

  let isApproval = false;
  const approvalBlocks = wf.blocks.filter((block) => block.key === 'requestApproval');
  approvalBlocks.forEach((block) => {
    const blocksToApprove = block.variables['blocks-to-approve']?.value;
    if (blocksToApprove.includes(startBlock.id)) {
      isApproval = true;
    }
  });
  selected.value = !selected.value;
  emit('toggle', selected.value, props.workflow, isApproval);
};

const hasRequiredFields = computed(() => {
  if (!requiredFields.value) return false;
  return Object.keys(requiredFields.value).length > 0;
})

const mapping = ref([]);
const handleSelection = () => {
  emit('field-map-update', mapping.value, props.workflow.id);
};

const getMappingValue = (sourceVar) => {
  const existingMapping = getCurrentMapping.value?.find((map) => {
    const sameVariable = map.id === sourceVar.variableName;
    return sameVariable;
  })
  return existingMapping;
}

const requiredFields = ref(null);
const getOrgUsers = computed(() => {
  const orgUsers = [];
  const seenEmails = new Set();

  listUserGroups.value.forEach((group) => {
    group.members.forEach((member) => {
      if (member.email && seenEmails.has(member.email)) return;

      seenEmails.add(member.email);
      orgUsers.push({
        icon: 'fa-users',
        id: member.groupuser_id,
        title: 'User',
        value: member.email,
        source: 'users',
        sourceTitle: 'Organization users',
      });
    })
  });

  return orgUsers;
});

const availableOptions = computed(() => {
  const upstreamBlocks = props.workflow.blocks;
  const allInputs = [];

  const cloneBlock = _.cloneDeep(props.workflow.blocks[0])
  cloneBlock.actions.loadTemplateIntoBlock(cloneBlock, props.template);
  upstreamBlocks.forEach((block) => {
    if (block.key === 'startAgreement') block = cloneBlock;

    const { actions: { getAvailableInputs } } = block;
    if (!getAvailableInputs) return;

    const inputs = getAvailableInputs(block);
    allInputs.push(...inputs);
  });

  return allInputs;
});

const getOptions = computed(() => {
  const allOptions = [
    ...getOrgUsers.value,
    ...availableOptions.value,
  ];
  return allOptions;
});

const restoreExistingMappings = () => {
  const seenVars = new Set();
  requiredFields.value = getRequiredVars(props.workflow, props.template);
  requiredFields.value.forEach((item) => {
    const variableName = item.variableName;

    // Prevent duplicate variables being mapped
    if (seenVars.has(variableName)) return;
    seenVars.add(variableName);

    const restoredMap = getMappingValue(item);
    const varMapping = {
      id: variableName,
      source: item,
      destination: restoredMap?.destination || null,
    }
    mapping.value.push(varMapping);
  });
}

onMounted(() => {
  restoreExistingMappings();
})

</script>

<template>
  <div class="wf-container">
    <div class="wf-title-row">
      <div class="left-side">
        <div class="row-icon">
          <i class="fal fa-code-branch"></i>
        </div>
        <div class="row-details">
          <div class="wf-name">{{ props.workflow.name }}</div>
          <div class="wf-group">{{ getGroupOrCreator }}</div>
          <div class="wf-description">{{ props.workflow.description }}</div>
        </div>
      </div>

      <div class="right-side">
        <hrbrSwitch :toggled-on="selected" @click="switchClickHandler"> </hrbrSwitch>
      </div>
    </div>

    <div class="wf-contents" v-if="selected && hasRequiredFields">
      <div class="field-row block-heading block-container">
        <div class="field-heading-left">Block variable</div>
        <div class="arrow"></div>
        <div class="field-row-cell">Map from</div>
      </div>

      <div v-for="(item, index) in mapping" class="block-container" :key="index">
        <div class="workflow-field">
          <WfVariablePill
                class="autocomplete-pill"
                :key="item.key"
                :variable="item.source"
                :is-locked="true"
                :allow-drag="false"
                :allow-click="false" />
        </div>
        <div v-html="ArrowRight" class="arrow"></div>
        <div class="document-field">
          <WfVariableMapperField
            :mapping="item"
            :autocomplete-data="getOptions"
            @selection="handleSelection "/>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.quill {
  user-select: none;
  pointer-events: none;
  opacity: 0.5;
}
.input-quill {
  height: 100%;
  min-height: 100%;
}
.field-heading-left {
  width: 300px;
}
.input-container {
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  min-height: 28px;
  border: 1px solid #dadada;
  border-radius: 8px;
  padding: 8px;
  margin: 3px 0;
  flex-grow: 1;
  width: 100%;
  background-color: #ffffff77;
  position: relative;
}
.email-list-row {
  display: flex;
  align-items: center;
  position: relative;
}
.email-list-row i {
  margin-right: 10px;
}
.document-field {
  flex-grow: 1;
  height: 100%;
}
.workflow-field {
  border-radius: 8px;
  border: 1px solid #DBDBDB;
  padding: 8px 12px;
  font-size: 13px;
  width: 300px;
}
.arrow {
  margin: 0 20px;
  width: 25px;
}
.workflow-field i {
  margin-right: 5px;
}
.variable-row {
  display: flex;
}
.field-row-cell {
  width: 50%;
  padding-right: 5px;
}
.wf-contents {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
}
.wf-title-row {
  font-size: 14px;
  display: flex;
  width: 100%;
  align-items: center;
  border-top: 1px solid #dbdbdb;
  padding: 12px 0;
}
.left-side {
  flex-grow: 1;
  display: flex;
  align-items: center;
}
.row-details {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.row-icon i {
  font-weight: 500;
}
.row-icon {
  font-size: 20px;
  margin-right: 15px;
}
.block-container {
  display: flex;
  align-items: center;
  height: 100%;
}
.field-row {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 5px 0;
}
.field-row-cell {
  width: 50%;
  padding-right: 5px;
}
.block-heading {
  font-size: 14px;
  font-weight: 600;
  padding: 5px 0;
}
.frc-header {
  font-weight: 600;
}
.modal-card-head-icon {
  margin-right: 10px;
}

.block-header-row {
  display: flex;
  align-items: center;
}

.modal-card-head-icon {
  margin-right: 10px;
}
.frc-header {
  color: 999;
}
.wf-group {
  font-weight: 200;
  font-size: 13px;
}
.wf-name {
  font-weight: 600;
}
</style>

<script setup>
import { useSubscriptionsStore } from '@/stores/api/subscriptions-store';
import { computed, onMounted, ref } from 'vue';
const props = defineProps({
  customer_id: {
    type: String,
    required: false,
  },
  harplus: {
    type: Object,
  },
  gae_service: {
    type: String,
    default: 'canary',
  },
  system_email: {
    type: String,
    required: false,
  },
});

const subscriptionsStore = useSubscriptionsStore();

const isLoading = ref(true);
const title = 'Choose the plan that <span class="highlighted">works</span> for you';
const imageFolder = '/static/imgs/';
const isPlanMonthlyInterval = ref(true);
const plans = ref([
  {
    title: 'Harbour+',
    productid: 'prod_MmGgevsdSVWsyW',
    price: { amount: 100, stripeid: 'price_1M2i9CDe9qYhn5PzvGcHJ7Up' },
    prices: {
      monthly: { amount: 100, stripeid: 'price_1M2i9CDe9qYhn5PzvGcHJ7Up', freq: 'Month' },
      annually: { amount: 960, stripeid: 'price_1M6enYDe9qYhn5PzlvZx5Hn1', freq: 'Year' },
    },
    description: '',
    priceOptions: true,
    image: 'boat2.svg',
    priceType: 'Monthly',
    featured: true,
    features: [
      '50 Signed Agreements per month',
      '50 Templates per month',
      'Over 150 Integrations',
      'Unlimited Users',
      'Priority chat and email support',
    ],
    buttonText: 'Sign Up',
    isLoading: false,
  },
  {
    productid: 'prod_MmGkoU2MgsdTPg',
    title: 'Enterprise',
    price: 'Custom',
    description: '',
    image: 'boat3.svg',
    billing: 'Customized solution',
    featured: false,
    features: [
      'Volume based pricing for scale',
      'Unlimited Templates',
      'Unlimited Integrations + Site Embeds',
      'Data Migration with Assisted Setup',
      'Dedicated Support',
    ],
    buttonText: 'Schedule Demo',
    isLoading: false,
  },
]);

const customerId = computed(() => props.customer_id || null);

onMounted(() => {
  isPlanMonthlyInterval.value = props.harplus?.interval !== 'annually';
  if (props.harplus?.redirect === true) {
    createSubscription();
  }
});

const updatePriceType = (type) => {
  isPlanMonthlyInterval.value = type === 'month';
};

const createSubscription = async () => {
  const res = await subscriptionsStore.addSubscription({
    plan_type: 'plus',
    plan_interval: isPlanMonthlyInterval.value ? 'month' : 'year',
    customer_email: props.system_email ?? null,
    billing_email: props.system_email ?? null,
    customer_id: customerId.value ?? null,
    success_url: `${window.location.origin}/dashboard?ref=plus`,
    cancel_url: `${window.location.origin}/dashboard`,
  });
  if (res.error) {
    alert('Error while creating subscription! Please try again or contact support.');
    isLoading.value = false;
    return;
  }
  window.open(res.checkout_session_url, '_self');
};

const handleButtonClick = (plan) => {
  if (!plan.isLoading) plan.isLoading = true;
  else return;

  if (!plan.prices) {
    window.open('https://www.harbourshare.com/request-a-demo', '_blank');
    plan.isLoading = false;
  } else {
    createSubscription();
  }
};
</script>

<template>
  <div class="harbour-plus-modal">
    <div class="stripe-table-container">
      <div class="stripepage-container" v-if="!harplus?.redirect">
        <div class="stripepage-title">
          <p class="title has-text-centered" v-html="title"></p>
        </div>

        <div class="slider-buttons">
          <div :class="['slider-btn', isPlanMonthlyInterval && 'active']" @click="updatePriceType('month')">
            Monthly
          </div>
          <div :class="['slider-btn', !isPlanMonthlyInterval && 'active']" @click="updatePriceType('year')">
            Annual (save 20%)
          </div>
        </div>

        <!-- Plan options-->
        <div class="plan-options-container">
          <div v-for="plan in plans" class="stripe-plan" :class="plan.featured ? 'featured' : ''">
            <img class="stripe-plan-bg" src="/static/imgs/price-card-bg-2.svg" alt="bg" />
            <!-- SINGLE PLAN -->
            <div class="stripepage-column">
              <div class="plan-header">
                <div>
                  <div class="plan-image">
                    <img :src="imageFolder + plan.image" class="icons" />
                  </div>
                </div>
                <div class="plan-title">
                  <span class="stripe-bold">{{ plan.title }}</span>
                </div>
                <div>
                  <span class="price" v-if="plan.prices">
                    ${{
                      isPlanMonthlyInterval
                        ? plan.prices.monthly.amount
                        : plan.prices.annually.amount
                    }}<span class="price-frequency">/{{ isPlanMonthlyInterval ? 'Month' : 'Year' }}</span>
                  </span>
                  <span class="price" v-else>{{ plan.billing }}</span>
                </div>
              </div>

              <div class="stripepage-features">
                <ul>
                  <li v-for="item in plan.features" class="stripe-listitem">
                    <i class="fas fa-check"></i>
                    <span class="feature-description">{{ item }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <div class="stripepage-button-container">
                <b-button class="stripe-button is-harbour" @click="handleButtonClick(plan)" :loading="plan.isLoading"
                  :disabled="plan.isLoading">{{ plan.buttonText }}</b-button>
              </div>
            </div>
            <!-- single plan end -->
          </div>
        </div>
        <!-- Plan options end -->
      </div>
      <div class="stripepage-container" style="background-position: -200px -200px" v-else-if="harplus?.redirect">
        <div class="stripepage-title featured"
          style="background-color: white; border-radius: 10px; padding: 60px; margin: 60px">
          <div style="padding: 60px">
            <p class="title has-text-centered is-size-4 pb-6">Harbour+</p>
            <b-loading :is-full-page="false" :active="isLoading" :can-cancel="false" animation=""></b-loading>
            <p class="subtitle has-text-centered is-size-6 pt-6">
              Please wait. <br />Redirecting to Stripe checkout page to complete your upgrade.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@700&display=swap');

.harbour-plus-modal {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  border-radius: 6px;
  background-color: #fff;
  letter-spacing: -0.16px;

  --primary-400b: #3d71f5;
  --neutral-400: #acb4c3;
  --neutral-200: #e7eaee;
  --slate-300: #67718e;
  --primary-600: #0133b7;
}

.stripe-table-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  z-index: 1;
}

.stripe-table-container a {
  text-decoration: none;
}

.stripe-table-container .has-text-centered {
  text-align: center !important;
}

.stripepage-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
}

.stripepage-title {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stripepage-title>p {
  margin: 0;
  padding: 0 0 20px 0;
}

.stripepage-pill {
  display: block;
  background-color: #fff;
  color: #1355ff;
  font-size: 12px;
  padding: 4px 10px;
  margin: 20px 0;
  border-radius: 20px;
  border: 1px solid #1355ff;
  text-align: center;
  font-weight: 500;
}

.stripe-table-container .title {
  font-family: 'Plus Jakarta Sans', sans-serif;
  max-width: 400px;
  color: #061237;
  text-align: center;
  font-size: 2.7em;
  font-weight: 700;
  letter-spacing: 0;

  >>>.highlighted {
    color: #1355ff;
  }
}

.stripe-table-container .subtitle {
  color: #061237;
  font-size: 1.2em;
  font-weight: 500;
  letter-spacing: 1px;
}

/* PLANS */
.plan-options-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  width: 95%;
}

.stripe-plan {
  position: relative;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 95%;
  max-width: 350px;
  border: 1px solid var(--primary-400b);
  border-radius: 12px;
  background-color: white;
  box-shadow: -9px 48px 124px -24px rgba(1, 51, 183, 0.1);
  color: #07090d;
  transition: 0.3s ease-in-out;

  &:hover {
    --primary-400b: #1355ff;
    --neutral-400: #fff;
    --slate-300: #fff;
    --primary-600: #fff;
    background: #1355ff;
    color: #f7f9fc;

    .stripepage-features {
      border-bottom-color: hsla(0, 0%, 100%, 0.1);
    }

    .stripepage-features .fa-check {
      color: #0133b7;
      background-color: #fff;
    }
  }

  &-bg {
    bottom: 0;
    height: auto;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 0;
  }
}

.stripepage-column {
  padding: 10px 35px;
  z-index: 1;
}

.plan-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
}

.plan-header>div {
  display: flex;
  justify-content: center;
}

.plan-image {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  height: 65px;
  width: 65px;
  background: #fff;
  border: 0.5px solid var(--primary-400b);
  border-radius: 50%;
  -webkit-box-shadow: inset 0 -1px 2px 0 rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 -1px 2px 0 rgba(0, 0, 0, 0.1);
  -webkit-filter: drop-shadow(0 4px 15px rgba(19, 85, 255, 0.1));
  filter: drop-shadow(0 4px 15px rgba(19, 85, 255, 0.1));

  img {
    max-height: 60%;
    max-width: 80%;
  }
}

.stripe-table-container .icons {
  width: 100%;
  height: auto;
}

.plan-title {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 40px;
  font-weight: 700;
  text-align: center;
}

.stripe-table-container .price {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 20px;
  font-weight: 700;
}

.price-frequency {
  margin-left: 3px;
  color: var(--neutral-400);
  font-size: 14px;
  font-weight: 400;
  position: relative;
  top: -5px;
}

.stripemodal ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.stripemodal ul li {
  margin: 0;
  padding: 0;
}

/* Feature lists */
.stripepage-features {
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  color: var(--slate-300);
}

.stripepage-features ul {
  list-style: none;
  width: 100%;
  margin: 0;
  padding: 20px 0;
  border-bottom: 1px solid var(--neutral-200);
  transition: 0.3s ease-in-out;
}

.stripe-listitem {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 10px 0 10px 0 !important;
  font-size: 14px;
  font-weight: 500;
}

.stripe-listitem>.icon {
  border-radius: 10px;
}

.stripe-listitem>span {
  line-height: 25px;
}

.stripepage-features .fa-check {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #0133b7;
  padding: 6px;
  font-size: 10px;
  font-weight: 600;
  color: #fff;
  transition: 0.3s ease-in-out;
}

.feature-description {
  padding-left: 15px;
}

.slider-buttons {
  margin-bottom: 10px;
  background: #f7f9fc;
  border-radius: 24px;
  font-size: 14px;
  padding: 4px;
  font-weight: 500;

  .slider-btn {
    border-radius: 20px;
    color: var(--slate-300);
    cursor: pointer;
    display: inline-block;
    padding: 11px 20px 11px 21px;
    transition: 0.3s ease-in-out;

    &:first-child {
      margin-right: 5px;
    }

    &.active {
      box-shadow: 0 8px 8px 0 rgba(113, 116, 152, 0.05), 0 8px 16px 0 rgba(113, 116, 152, 0.07),
        0 18px 31px 0 rgba(113, 116, 152, 0.1);
      color: var(--primary-600);
      background-color: #fff;
    }
  }
}

/* Button */
.stripepage-button-container {
  padding: 25px 35px;
}

.is-harbour {
  font-size: 0.9em;
  padding: 20px 0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  letter-spacing: -0.09px;
  font-weight: 600;
  cursor: pointer;
  background-color: white;
  border: 1px solid #e7eaee;
  color: #07090d;
}

.stripepage-button-container button:disabled {
  background-color: #cccccc;
  color: #333;
  opacity: 1;
}

@media only screen and (min-width: 800px) {
  .plan-options-container {
    max-width: 1000px;
    min-width: 800px;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    margin: 0;
  }

  .stripe-plan {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 300px;
    margin: 20px 20px;
  }

  .stripepage-column {
    padding: 0 35px;
  }
}
</style>

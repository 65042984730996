import { CK_BUNDLE_VERSION } from '@/components/Ckeditor/lib/bundle-version';
import {
  DOCUMENT_COLOR,
  NO_FONT_FAMILY_INPUTS,
  NO_FONT_SIZE_CONTROL_INPUTS,
} from '@/config/agreement-editor';
import { ANNOTATION_FONT_SIZE_DEFAULT } from '@/config/ckeditor';
import { defineDraftRole } from '@/domain/drafts/draft';
import { useDraftsStore } from '@/pages/Drafts/stores/drafts-store';
import ckeditorApiService from '@/services/ckeditor-api-service';
import templateApiService from '@/services/templates-api-service';
import { useHarbourStore } from '@/stores/harbour-store';
import axios from 'axios';
import { ToastProgrammatic as Toast } from 'buefy';
import { defineStore } from 'pinia';
import SparkMD5 from 'spark-md5';
import Vue from 'vue';
import { DOCX_CONVERTER_CSS_PATH, DOCX_CONVERTER_OPTIONS, DOCX_CONVERTER_URL } from '../lib/docx';
import {
  PDF_CONVERTER_CSS_PATH,
  PDF_CONVERTER_OPTIONS,
  PDF_CONVERTER_URL,
  SCALE_FACTOR,
  getPdfjsDocument,
  getPdfjsPages,
} from '../lib/pdf';
import deprecatedActions from './deprecated-actions';
import htmlProcessingActions from './html-processing-actions';

const uploadTimestamps = {};
const UPLOAD_TIME_LIMIT = 30000; // 30 seconds

export const useCkeditorStore = defineStore('ckeditor', {
  state: () => ({
    harbourStore: useHarbourStore(),
    draftsStore: useDraftsStore(),

    inputFontSizeMap: {},
  }),

  actions: {
    ...htmlProcessingActions,
    ...deprecatedActions,

    async loadCkeditorMetadata(fileDisplayId) {
      const respData = await ckeditorApiService.loadMetadata(fileDisplayId).catch(() => null);
      return respData;
    },

    async saveCkeditorMetadata({ fileDisplayId, refids, suggestions, comments }) {
      const respData = await ckeditorApiService
        .saveMetadata({ fileDisplayId, refids, suggestions, comments })
        .catch(() => null);
      return respData;
    },

    async saveCkeditorDocumentSharing({
      fileDisplayId,
      writers = [],
      commentators = [],
      readers = [],
      message = '',
      skipnotify = false,
      documentTitle = 'Untitled',
      linkDisplayId = null,
    }) {
      const respData = await ckeditorApiService
        .saveDocumentSharing({
          fileDisplayId,
          writers,
          commentators,
          readers,
          message,
          skipnotify,
          documentTitle,
          linkDisplayId,
        })
        .catch(() => null);
      return respData;
    },

    async loadCkeditorDocumentSharing({
      fileDisplayId,
      isGetInitialData = false,
      linkDisplayId = null,
    }) {
      const respData = await ckeditorApiService
        .loadDocumentSharing({ fileDisplayId, isGetInitialData, linkDisplayId })
        .catch(() => null);
      return respData;
    },

    async lockCkeditorCollaboration({ fileDisplayId, isCollabEnabled }) {
      const respData = await ckeditorApiService
        .lockCollaboration({ fileDisplayId, isCollabEnabled })
        .catch(() => null);
      return respData;
    },

    async loadCkeditorDocumentSettings(fileDisplayId, linkDisplayId = null) {
      const respData = await ckeditorApiService
        .loadDocumentSettings(fileDisplayId, linkDisplayId)
        .catch(() => null);
      return respData;
    },

    async syncCkeditorDocumentMetadata({ fileDisplayId, suggestions, comments }) {
      const respData = await ckeditorApiService
        .syncDocumentMetadata({ fileDisplayId, suggestions, comments })
        .catch(() => null);
      return respData;
    },

    async updateCkeditorDraftHtml({ fileDisplayId, html }) {
      const respData = await ckeditorApiService
        .updateDraftHtml({ fileDisplayId, html })
        .catch(() => null);
      return respData;
    },

    async getCkeditorTemplateHtml(templateId, linkId = null) {
      const respData = await ckeditorApiService
        .getTemplateHtml(templateId, linkId)
        .catch(() => null);
      if (respData) return respData.html;
      return respData;
    },

    async cloneCkeditorLink({ agreementId, ckeditorFileId, linkItem, isTemplate = false }) {
      // New process for CK Editor documents. Must duplicate ageement
      // Then store a draft object, initialize docsharing and metadata
      let response = await Vue.prototype.$harbourData.post(
        '/data?agreement_editor_copy_agreement_template',
        {
          requesttype: 'agreement_editor_copy_agreement_template',
          agreementid: agreementId,
          filedisplayid: ckeditorFileId,
          istemplate: isTemplate,
        },
      );

      // Get the new CK ID from the response
      let updatedCkId = response.data?.newfileid;
      let updatedAgreementId = response.data?.copiedagreementid;
      let linkItemName = linkItem.client_importbyurl_linkrecordtitle;

      await this.saveCkeditorDocumentSharing({
        fileDisplayId: updatedCkId,
      });
      await this.saveCkeditorMetadata({
        fileDisplayId: updatedCkId,
        refids: { activeagreementid: updatedAgreementId },
      });
      await this.draftsStore.storeDraft({
        name: linkItemName,
        ckFileId: updatedCkId,
        agreementId: updatedAgreementId,
        role: defineDraftRole(isTemplate),
      });

      response.data.updatedCkId = updatedCkId;
      response.data.updatedAgreementId = updatedAgreementId;
      return response;
    },

    async copyCkeditorTemplateSaveMeta(template, addCopyToTitle = true, isTemplate = true) {
      const templateId = template?.agreement_id;
      const customInputs = template?.custom_input_fields_json;
      const ckeditorFileId = customInputs?.ckeditoragreementid;
      let updatedCkId = null;
      let updatedAgreementId = null;

      try {
        const ckeditorHtmlPromise = this.getCkeditorTemplateHtml(templateId);
        const respPromise = templateApiService.copyAgreementTemplateRequest({
          templateId,
          fileDisplayId: ckeditorFileId,
          isTemplate,
          addCopyToTitle,
        });

        let [ckeditorHtmlData, respData] = await Promise.all([ckeditorHtmlPromise, respPromise]);

        ckeditorHtmlData = this.removeCommentsAndSuggestions(ckeditorHtmlData);

        updatedCkId = respData?.newfileid;
        updatedAgreementId = respData?.copiedagreementid;

        if (ckeditorFileId === updatedCkId || templateId === updatedAgreementId) {
          throw new Error('Error copying CK agreement - new ID is the same as old ID.');
        }

        const documentSharingPromise = this.saveCkeditorDocumentSharing({
          fileDisplayId: updatedCkId,
        });
        const metadataPromise = this.saveCkeditorMetadata({
          fileDisplayId: updatedCkId,
          refids: { activeagreementid: updatedAgreementId },
        });
        await Promise.all([documentSharingPromise, metadataPromise]);

        return {
          ckeditorHtmlData,
          ckeditorFileId: updatedCkId,
          agreementId: updatedAgreementId,
        };
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    async prepareForExport(title, editorHTML, comments = null, suggestions = null, convertTo) {
      let documentTitle = title || 'document.docx';
      // make sure the file name ends with .docx and doesn't end with .pdf
      if (!documentTitle.endsWith('.docx')) {
        documentTitle = documentTitle.replace(/\.pdf$/, '');
        documentTitle += '.docx';
      }

      const convertToDocx = convertTo === 'docx';

      let formattedSugestions = null;
      if (suggestions) {
        formattedSugestions = [];
        suggestions.forEach((suggestion) => {
          formattedSugestions.push({
            id: suggestion.id,
            author: suggestion.author.name,
            created_at: suggestion.createdAt,
          });
        });
      }

      const styleFunctions = [this.getDocxConverterStyles, this.getPdfConverterStyles];
      const styleGetter = convertToDocx ? styleFunctions[0] : styleFunctions[1];
      const css = await styleGetter();

      let html = editorHTML;

      // if exporting without comments or suggestions, strip comments and suggestions from html
      // or else ckeditor exporter will reject.
      const hasComments = !!comments?.length;
      const hasSuggestions = !!suggestions?.length;
      const shouldStripComments = !hasComments || !hasSuggestions;
      if (shouldStripComments) {
        html = this.removeCommentsAndSuggestions(html);
      }

      // Export to DOCX.
      if (convertToDocx) {
        return {
          html,
          css,
          config: {
            ...DOCX_CONVERTER_OPTIONS,
            collaboration_features: {
              comment_threads: comments || [],
              suggestions: suggestions ? formattedSugestions : [],
            },
          },
        };
      }

      // Export to PDF.
      return {
        html,
        css,
        // these config values must match pagination configuration.
        options: {
          ...PDF_CONVERTER_OPTIONS,
        },
      };
    },

    async getStandardizedDocx(file, convertFrom = 'docx') {
      const data = new FormData();
      data.append('file', file);
      data.append('convert_from', convertFrom);

      let newFile = null;
      let errorString = null;
      try {
        // stringify formData for logging
        const dataEntries = Array.from(data.entries());
        const dataEntriesString = dataEntries.map((entry) => `${entry[0]}=${entry[1]}`).join(', ');
        console.log('getStandardizedDocx request data', dataEntriesString);

        const response = await axios.post('/docx-standardize', data, {
          responseType: 'blob',
        });

        if (response.status !== 200) {
          errorString =
            'getStandardizedDocx error - status not 200 - ' +
            response.status +
            ' - ' +
            response.data;
        } else {
          console.log('getStandardizedDocx success response', response);
          newFile = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          });
        }
      } catch (error) {
        try {
          errorString = 'getStandardizedDocx error - error getting response - ' + error;
        } catch (additionalError) {
          errorString =
            'getStandardizedDocx error - error getting response - ' +
            error +
            ' - additional error - ' +
            additionalError;
        }
      }

      if (errorString) {
        // log request form data with response error string
        const dataEntries = Array.from(data.entries());
        const dataEntriesString = dataEntries.map((entry) => `${entry[0]}=${entry[1]}`).join(', ');

        errorString = `${errorString} - data: ${dataEntriesString}`;
        console.log(errorString);
        // suppress "sentry not defined" error in local dev with try-catch
        try {
          Sentry.captureException(new Error(errorString));
        } catch (err) {}
        return null;
      }
      return newFile;
    },

    async exportToFile(title, editorHTML, comments = null, suggestions = null, convertTo = null) {
      // for blank document (converters don't play nice with empty strings)
      if (!editorHTML) editorHTML = ' ';
      const payload = await this.prepareForExport(
        title,
        editorHTML,
        comments,
        suggestions,
        convertTo,
      );

      const tokenResp = await fetch('/cke-token');
      const tokenData = await tokenResp.json();
      if (tokenData?.state !== 'SUCCESS') return null;
      const { token } = tokenData;

      convertTo = convertTo?.toLowerCase();
      const url = convertTo === 'pdf' ? PDF_CONVERTER_URL : DOCX_CONVERTER_URL;

      let convert = null;
      try {
        convert = await axios.post(url, payload, {
          headers: {
            Authorization: token,
            'Content-Type': 'application/json',
          },
          responseType: 'blob',
        });
      } catch (err) {
        console.error(err);
        return null;
      }
      if (convert.status !== 200) return null;

      // send to standardizer if docx, otherwise return data
      if (convertTo === 'docx') {
        const standardized = await this.getStandardizedDocx(convert.data);
        // return if successful, else return original
        if (standardized) return standardized;
      }
      return convert.data;
    },

    async exportToPdf(title, editorHTML) {
      return await this.exportToFile(title, editorHTML, null, null, 'pdf');
    },

    async exportToDocx(title, editorHTML, comments = null, suggestions = null) {
      const html = this.toExportDocxHtml(editorHTML);
      return await this.exportToFile(title, html, comments, suggestions, 'docx');
    },

    async convertCkeditorDataToPdf(fileId, html, linkDisplayId = null) {
      const css = await this.getPdfConverterStyles();

      let token = null;
      if (fileId) {
        let tokenUrl = `/document-token/${fileId}`;
        if (linkDisplayId) {
          tokenUrl += `?link_display_id=${linkDisplayId}`;
        }
        const tokenResp = await axios.get(tokenUrl);
        token = tokenResp.data;
      } else {
        const tokenResp = await fetch('/cke-token');
        const tokenData = await tokenResp.json();
        if (tokenData?.state !== 'SUCCESS') return null;
        token = tokenData.token;
      }

      const pdfResp = await axios({
        responseType: 'blob',
        method: 'post',
        url: PDF_CONVERTER_URL,
        data: {
          html,
          css,
          // These configuration values must match Pagination configuration.
          options: PDF_CONVERTER_OPTIONS,
        },
        headers: {
          Authorization: token,
        },
      });

      // binary string
      return pdfResp.data;
    },

    async getDocxConverterStyles() {
      const css = await fetch(DOCX_CONVERTER_CSS_PATH)
        .then((resp) => resp.text())
        .catch(() => '');
      return css;
    },

    async getPdfConverterStyles() {
      const css = await fetch(PDF_CONVERTER_CSS_PATH)
        .then((resp) => resp.text())
        .catch(() => '');
      return css;
    },

    async getCkeditorPdfPages(fileBase64, fileId) {
      if (!fileBase64 || !fileId) return;

      const pdfjsDocument = await getPdfjsDocument(fileBase64);
      const firstPage = 1;
      const lastPage = pdfjsDocument.numPages;

      const pdfjsPages = await getPdfjsPages(pdfjsDocument, firstPage, lastPage);
      const [pdfjsPage] = pdfjsPages; // first page
      const viewport = pdfjsPage.getViewport({ scale: SCALE_FACTOR });

      const pages = pdfjsPages.map((page) => ({
        pageheightpx: viewport.height,
        pageid: `page-${Math.random().toString(36).slice(2)}-${Date.now()}`,
        pageisactive: true,
        pageoriginalpdfpagenumber: page.pageNumber,
        pagepngbase64: null,
        pagescalefactor: SCALE_FACTOR,
        pagewidthpx: viewport.width,
        pdfid: fileId,
      }));
      return pages;
    },

    /**
     * Creates object of attributes for ckeditor annotation.
     * Will be used by ckeditor writer when making a new annotion.
     *
     * Ckeditor annotations are defined here:
     * static/third-party/ckeditor5/src/annotation-element-structure.js
     * static/third-party/ckeditor5/src/annotation-editing.js in _defineSchema()
     * @param {Object} options
     * @param {String} options.displayLabel - text to display on annotation
     * @param {String} options.itemId - id of the corresponding input item
     * @param {String} options.itemFieldType - type of the corresponding input
     * item (e.g. 'TEXTINPUT')
     * @param {String} options.color - color of the annotation
     * @param {String} options.fontFamily - font-family of the annotation
     */
    createCkeditorAnnotation({
      displayLabel = '',
      itemId = null,
      itemFieldType = null,
      color = '#2d71ad',
      fontFamily = 'Arial',
      fontSize = ANNOTATION_FONT_SIZE_DEFAULT,
      isMultiple = false,
    } = {}) {
      const style = this.generateAnnotationStyle({
        fontFamily,
        fontSize,
        color,
        itemFieldType,
      });

      return {
        displayLabel,
        itemId,
        itemFieldType,
        style,
        isMultiple,
      };
    },

    updateAnnotationsFontSize(editor, input, newFontSize) {
      const newAnnotationObj = this.createCkeditorAnnotation({
        displayLabel: input.itemdefaultvalue || input.itemdisplaylabel,
        itemId: input.id,
        itemFieldType: input.itemfieldtype,
        color: input.itemcolor,
        fontFamily: input.fontfamily,
        fontSize: newFontSize,
      });
      this.replaceCkeditorAnnotations(editor, input.id, newAnnotationObj);
    },

    buildInputFontSizeMap(editor) {
      if (!editor) return;

      try {
        const root = editor.model.document.getRoot();
        const range = editor.model.createRangeIn(root);
        const items = Array.from(range.getWalker({ ignoreElementEnd: true }));

        const hasFontSize = (itemFieldType) => !NO_FONT_SIZE_CONTROL_INPUTS.includes(itemFieldType);
        const predicate = (value) =>
          value.item.name === 'annotation' && hasFontSize(value.item.getAttribute('itemFieldType'));

        const annotations = items.filter(predicate);
        if (!annotations.length) {
          this.inputFontSizeMap = {};
          return;
        }

        const fontSizeMap = {};
        for (const annotation of annotations) {
          const itemId = annotation.item.getAttribute('itemId');
          if (itemId in this.inputFontSizeMap) continue;
          const style = annotation.item.getAttribute('style');
          const fontSize = this.parseAnnotationFontSize(style) || ANNOTATION_FONT_SIZE_DEFAULT;
          fontSizeMap[itemId] = fontSize;
        }
        this.inputFontSizeMap = fontSizeMap;
      } catch (err) {
        this.inputFontSizeMap = {};
        console.error(err);
      }
    },

    updateInputFontSizeMap(inputId, fontSize) {
      this.inputFontSizeMap[inputId] = fontSize;
    },

    resetInputFontSizeMap() {
      this.inputFontSizeMap = {};
    },

    parseAnnotationFontSize(styleStr = '') {
      let fontSize = null;
      const styleArr = styleStr.split(';');
      for (const style of styleArr) {
        if (!style.includes('font-size')) continue;
        const [, fontSizeVal] = style.split(':');
        const fontSizeNum = parseInt(fontSizeVal, 10);
        if (!Number.isNaN(fontSizeNum)) fontSize = fontSizeNum;
      }
      return fontSize;
    },

    updateAnnotationsFontFamily({ editor, input, fontFamily }) {
      const fontSize = this.inputFontSizeMap[input.id] || ANNOTATION_FONT_SIZE_DEFAULT;
      const newAnnotationObj = this.createCkeditorAnnotation({
        displayLabel: input.itemdefaultvalue || input.itemdisplaylabel,
        itemId: input.id,
        itemFieldType: input.itemfieldtype,
        color: input.itemcolor,
        fontFamily,
        fontSize,
      });
      this.replaceCkeditorAnnotations(editor, input.id, newAnnotationObj);
    },

    /**
     * Change the text of checkbox ckeditor annotations by replacing them with new ones.
     * @param {Object} editor - ckeditor instance
     * @param {string} id - id of checkbox option
     * @param {string} itemcolor - color of the annotation
     * @param {string} newText - text the new annotation should contain
     * */
    updateCkeditorCheckboxAnnotationsText(editor, id, itemcolor, newText) {
      const newAnnotationObj = this.createCkeditorAnnotation({
        displayLabel: newText,
        itemId: id,
        itemFieldType: 'CHECKBOXINPUT',
        color: itemcolor,
      });
      this.replaceCkeditorAnnotations(editor, id, newAnnotationObj);
    },

    /**
     * Change the text of ckeditor annotations by replacing them with new ones.
     * @param {Object} editor - ckeditor instance
     * @param {Object} input - input object containing ID and color
     * @param {string} newText - text the new annotation should contain
     * */
    updateCkeditorAnnotationsText(editor, input, newText) {
      const fontSize = this.inputFontSizeMap[input.id] || ANNOTATION_FONT_SIZE_DEFAULT;
      const newAnnotationObj = this.createCkeditorAnnotation({
        displayLabel: newText,
        itemId: input.id,
        itemFieldType: input.itemfieldtype,
        color: input.itemcolor,
        fontFamily: input.fontfamily,
        fontSize,
        isMultiple: input.itemtypeconfig.multiple?.toString(),
      });
      this.replaceCkeditorAnnotations(editor, input.id, newAnnotationObj);
    },

    updateCkeditorAnnotationsStyle(editor, input) {
      const fontFamily = input.fontfamily || 'Arial';
      const fontSize = this.inputFontSizeMap[input.id] || ANNOTATION_FONT_SIZE_DEFAULT;
      const color = input.itemcolor || '#2d71ad';
      const itemFieldType = input.itemfieldtype;

      const style = this.generateAnnotationStyle({
        fontFamily,
        fontSize,
        color,
        itemFieldType,
      });

      const writerCallback = ({ annotation }) => {
        editor.model.enqueueChange((writer) => {
          writer.setAttribute('style', style, annotation.item);
        });
      };
      this.updateCkeditorAnnotations(editor, input.id, writerCallback);
    },

    updateCkeditorAnnotationsMultipleAttribute(editor, inputId, isMultiple) {
      const attrValue = isMultiple.toString();
      const annotations = this.getCkAnnotationsForInput(inputId, editor);
      annotations.forEach((annotation) => {
        editor.model.enqueueChange((writer) => {
          writer.setAttribute('isMultiple', attrValue, annotation.item);
        });
      });
    },

    /**
     * Replace ckeditor annotations with a given annotation.
     * This works by removing the target annotations and inserting new ones in their places.
     * Ckeditor's writer.createElement() to create new annotation
     * @param {Object} editor - ckeditor instance
     * @param {*} inputItemId - input id used to filter annotations
     * @param {*} newAnnotationObj - object of attributes passed to
     */
    replaceCkeditorAnnotations(editor, inputItemId, newAnnotationObj) {
      const writerCallback = ({ writer, annotation, model }) => {
        const annotationItemId = annotation.item.getAttribute('itemId');
        if (annotationItemId !== inputItemId) return;
        const newAnnotation = writer.createElement('annotation', newAnnotationObj);
        const position = model.createPositionAfter(annotation.item);
        model.insertContent(newAnnotation, position);
        writer.remove(annotation.item);
      };
      this.updateCkeditorAnnotations(editor, inputItemId, writerCallback);
    },

    /**
     * Replace ckeditor annotations with a given annotation.
     * @param {Object} editor - ckeditor instance
     * @param {Object} input - input object containing ID and color
     */
    replaceCkeditorAnnotationsByInput(editor, input) {
      const fontSize = this.inputFontSizeMap[input.id] || ANNOTATION_FONT_SIZE_DEFAULT;
      const newAnnotationObj = this.createCkeditorAnnotation({
        displayLabel: input.itemdefaultvalue || input.itemdisplaylabel,
        itemId: input.id,
        itemFieldType: input.itemfieldtype,
        color: input.itemcolor,
        fontFamily: input.fontfamily,
        fontSize,
      });
      this.replaceCkeditorAnnotations(editor, input.id, newAnnotationObj);
    },

    /**
     * Iterates through all ckeditor annotations and modifies them via ckeditor API.
     * (See https://ckeditor.com/docs/ckeditor5/latest/api/module_engine_model_writer-Writer.html)
     * Returns early if ckeditor is not initialized or used.
     * @param {Object} editor - ckeditor instance
     * @param {string} inputItemId - id of the input item that was removed
     * @param {function({ writer, annotation, model })} writerCallback - callback used to modify
     * ckeditor document via ckeditor API. (See link above re: writer)
     */
    updateCkeditorAnnotations(editor, inputItemId, writerCallback = () => null) {
      if (!editor || !inputItemId) return;

      try {
        editor.model.change((writer) => {
          // Get document root element
          const root = editor.model.document.getRoot();
          const documentRange = writer.createRangeIn(root);

          // Filter conditions
          const predicate = (value) =>
            value.item.name === 'annotation' && value.item.getAttribute('itemId') === inputItemId;

          // Iterate over all annotations in the document.
          // See https://ckeditor.com/docs/ckeditor5/latest/api/module_engine_view_range-Range.html#function-getWalker
          const annotations = Array.from(documentRange.getWalker({ ignoreElementEnd: true }));
          const matchingAnnotations = annotations.filter(predicate);
          if (!matchingAnnotations.length) return;

          // Perform CKEditor operation on each matching annotation with CKEditor model writer.
          // For model writer, see https://ckeditor.com/docs/ckeditor5/latest/api/module_engine_model_writer-Writer.html
          matchingAnnotations.forEach((annotation) => {
            return writerCallback({
              writer,
              annotation,
              model: editor.model,
            });
          });
        });
      } catch (err) {
        console.error(err);
      }
    },

    /**
     * Updated method to update annotations only when link builder is saved
     * Expects a list of all inputs and a dictionary of hidden conditional inputs
     */
    updateCkeditorAnnotationsInLink(
      editor,
      items,
      currentlyHiddenConditionalInputs = {},
      referenceAttachments = [],
    ) {
      if (!editor) return;

      // Get the change api ready
      editor.model.change((writer) => {
        const root = editor.model.document.getRoot();
        const documentRange = writer.createRangeIn(root);
        const annotations = Array.from(documentRange.getWalker({ ignoreElementEnd: true }));
        let multipleUploadValueCount = 0;
        let updatedInputId = null;

        // Loop through all eligible inputs
        items.forEach((input) => {
          const id = input.itemid;

          const predicate = (value) => {
            return value.item.name === 'annotation' && value.item.getAttribute('itemId') === id;
          };
          const matchingAnnotations = annotations.filter(predicate);
          matchingAnnotations?.forEach((match) => {
            const attrs = match.item._attrs;
            const text = attrs.get('displayLabel');

            if (updatedInputId && updatedInputId !== id) {
              multipleUploadValueCount = 0;
            }

            // Update the display label of the annotation if necessary
            if (text && input.itemlinkvalue && text !== input.itemlinkvalue) {
              const value =
                attrs.get('itemFieldType') === 'IMAGEINPUT'
                  ? this.getImageAnnotationValue(
                      input,
                      multipleUploadValueCount,
                      referenceAttachments,
                      text,
                    )
                  : input.itemlinkvalue;

              editor.model.enqueueChange((writer) => {
                writer.setAttribute('displayLabel', value, match.item);
              });
              multipleUploadValueCount++;
              updatedInputId = id;
            }

            // Update visibility - we need to show/hide the annotation
            // Note that we need to re-apply the default style when un-hiding a previously hidden annotation
            if (attrs.has('style')) {
              let style;
              if (!currentlyHiddenConditionalInputs[id]) {
                style = this.generateAoDefaultStyle(input);
              } else {
                style = 'display: none;';
              }
              editor.model.enqueueChange((writer) => {
                writer.setAttribute('style', style, match.item);
              });
            }
          });
        });
      });
    },

    getImageAnnotationValue(
      correspondingInput,
      imageCount,
      referenceAttachments,
      annotationText = '',
    ) {
      const index = correspondingInput.multiple ? imageCount : 0;
      const attachment = referenceAttachments.find(
        (a) => a.id === correspondingInput.itemlinkvalue[index]?.referenceattachmentid,
      );

      return attachment?.previewbase64 || annotationText || '';
    },

    async getCkeditorSuggestions(editor) {
      const trackChangesPlugin = await editor.plugins.get('TrackChanges');
      return trackChangesPlugin.getSuggestions();
    },

    async getCkeditorComments(editor) {
      const commentsRepository = await editor.plugins.get('CommentsRepository');
      const commentThreads = await commentsRepository.getCommentThreads();
      const threads = [];
      commentThreads.forEach((threadItem) => {
        const thread = {
          thread_id: threadItem.id,
          is_resolved: threadItem.isResolved,
          comments: [],
        };

        for (const comment of threadItem.comments) {
          const createdAt = new Date(comment.createdAt).toISOString();
          thread.comments.push({
            content: comment.content,
            author: comment.author.name,
            created_at: createdAt,
          });
        }

        threads.push(thread);
      });
      return threads;
    },

    generateAnnotationStyle({ fontFamily, fontSize, color, itemFieldType }) {
      const hasFontFamily = !NO_FONT_FAMILY_INPUTS.includes(itemFieldType);
      const fontFamilyStyle = hasFontFamily ? `font-family: ${fontFamily};` : '';
      const hasFontSize = !NO_FONT_SIZE_CONTROL_INPUTS.includes(itemFieldType);
      const fontSizeStyle = hasFontSize ? `font-size: ${fontSize}px;` : '';
      const style = `background-color:${color}33; ${fontFamilyStyle} ${fontSizeStyle}`.trim();
      return style;
    },

    // Generate default agreement owner annotation style
    generateAoDefaultStyle(input) {
      const fontFamily = input.fontfamily || 'Arial';
      const fontSize = this.inputFontSizeMap[input.itemid] || ANNOTATION_FONT_SIZE_DEFAULT;
      const style = this.generateAnnotationStyle({
        fontFamily,
        fontSize,
        color: '#980043',
        itemFieldType: input.itemtype,
      });
      return style;
    },

    getUpdatedCkeditorHtmlForStorage(
      htmlData,
      inputs,
      isLinkBuilderStep = true,
      referenceAttachments,
    ) {
      // create a new div element and insert the html data
      const div = document.createElement('div');
      div.innerHTML = htmlData;
      // loop over and update each annotation in the html from itemvalues
      const annotations = div.querySelectorAll('.annotation');
      let multipleUploadValueCount = 0;
      let updatedInputId = null;

      // link builder steps use itemid, other steps use id
      const idKey = isLinkBuilderStep ? 'itemid' : 'id';
      annotations.forEach((annotation) => {
        const itemId = annotation.getAttribute('data-itemid');
        const itemFieldType = annotation.getAttribute('data-itemfieldtype');
        const correspondingInput = inputs.find((input) => input[idKey] === itemId);

        // value to insert into annotation
        let newValue = null;

        // input not found, search for CHECKBOXINPUT
        if (!correspondingInput) {
          const checkboxInputs = inputs.filter((input) => input.itemfieldtype === 'CHECKBOXINPUT');
          for (const input of checkboxInputs) {
            if (newValue) break;
            for (const option of input.itemoptions) {
              if (option.itemid === itemId) {
                // replace empty string with whitespace
                if (isLinkBuilderStep) {
                  // fill with agreement owner checked value, specified at link builder step
                  newValue = input.itemlinkvalue[option.itemid] || '&nbsp;&nbsp;';
                } else {
                  // fill with default checked value
                  newValue = 'X';
                }
                break;
              }
            }
          }
        }

        // if input found (not checkboxinput), use value
        if (!newValue) {
          const valueKey = isLinkBuilderStep ? 'itemlinkvalue' : 'itemdefaultvalue';
          newValue = correspondingInput ? correspondingInput[valueKey] : '';
        }

        // add document owner annotation class if document inputs
        if (newValue && isLinkBuilderStep) {
          annotation.classList.add('document-owner-annotation');
          if (itemFieldType.toLowerCase() !== 'signatureinput') {
            if (itemFieldType.toLowerCase() === 'imageinput') {
              if (updatedInputId && updatedInputId !== correspondingInput[idKey]) {
                multipleUploadValueCount = 0;
              }

              newValue = this.getImageAnnotationValue(
                correspondingInput,
                multipleUploadValueCount,
                referenceAttachments,
              );
              multipleUploadValueCount++;
              updatedInputId = correspondingInput[idKey];
              annotation.innerHTML = newValue ? `<img src="${newValue}" alt="image" />` : '';
            } else {
              const annotationText = annotation.querySelector('.annotation-text');
              annotationText.innerHTML = newValue;
            }
          }
        }
      });

      const htmlElems = div.querySelectorAll('[data-defaultstyle="defaultContentWrapper"]');
      const removeHtmlElemOutsideAnnotation = (elem) => {
        const annotation = elem.closest('.annotation');
        if (!annotation) elem.remove();
      };
      htmlElems.forEach(removeHtmlElemOutsideAnnotation);

      const { innerHTML } = div;
      div.remove();
      return innerHTML;
    },

    getUpdatedCkeditorHtmlWithDocumentOwnerClass(
      htmlData,
      documentInputs,
      isLinkBuilderStep = false,
    ) {
      // create a new div element and insert the html data
      const div = document.createElement('div');
      div.innerHTML = htmlData;
      // loop over and update each annotation in the html from itemvalues
      const annotations = div.querySelectorAll('.annotation');
      // link builder steps use itemid, other steps use id
      const idKey = isLinkBuilderStep ? 'itemid' : 'id';

      annotations.forEach((annotation) => {
        const itemId = annotation.getAttribute('data-itemid');
        let correspondingInput = documentInputs.find((input) => input[idKey] === itemId);

        // input not found, search for CHECKBOXINPUT
        if (!correspondingInput) {
          const isCheckbox = (i) => i.itemfieldtype === 'CHECKBOXINPUT';
          const checkboxInputs = documentInputs.filter(isCheckbox);
          for (const input of checkboxInputs) {
            if (correspondingInput) break;
            for (const option of input.itemoptions) {
              if (option.itemid === itemId) {
                correspondingInput = input;
                break;
              }
            }
          }
        }
        // add document owner annotation class if document documentInputs
        if (correspondingInput) {
          annotation.classList.add('document-owner-annotation');
        }
      });

      const { innerHTML } = div;
      div.remove();
      return innerHTML;
    },

    async getCkeditorFileVersion(filedisplayid) {
      const respData = await this.loadCkeditorMetadata(filedisplayid);
      let bundleVersion = null;
      if (respData && 'ckeditorversion' in respData) {
        bundleVersion = respData.ckeditorversion;
      }
      return bundleVersion;
    },

    // Check if ckeditor version is out of date
    async flushCkeditorIfVersionMismatch(filedisplayid, fileBundleVersion) {
      let bundleVersion = CK_BUNDLE_VERSION;

      if (fileBundleVersion) {
        bundleVersion = fileBundleVersion;
      }
      console.log(`Document version: ${bundleVersion}, bundle version: ${CK_BUNDLE_VERSION}`);

      if (bundleVersion !== CK_BUNDLE_VERSION) {
        try {
          await Vue.prototype.$harbourData.post(`/document_flush_sessions/${filedisplayid}`, {
            bundleVersion: CK_BUNDLE_VERSION,
          });
          return true;
        } catch (err) {
          return false;
        }
      }
      return false;
    },

    async getCurrentCkeditorDocx(html, title = '') {
      if (html === null || html === undefined) {
        return [null, false];
      }

      // handle blank documents
      if (html.length === 0) html = ' ';

      const currentFileBlob = await this.exportToDocx(title, html, null, null);

      if (!currentFileBlob) return [null, false];
      const currentFile = new File([currentFileBlob], title);
      return [currentFile, false];
    },

    async downloadCurrentDocx(htmlData, title = '') {
      let [newBlob] = await this.getCurrentCkeditorDocx(htmlData);
      if (!newBlob) return;

      const download = async (file) => {
        const blobUrl = window.URL.createObjectURL(file);
        let filename = title || 'document.docx';
        if (filename.split('.').pop() !== 'docx') {
          filename += '.docx';
        }
        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', filename);
        document.body.append(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(blobUrl);
      };
      download(newBlob);
    },

    async storeFileVersionAndDocumentMetadata({
      fileVersionDisplayId,
      comments,
      suggestions,
      isOriginalFile,
      fileName,
      fileSizeBytes,
      ancestorDisplayId,
      fileBase64,
      inputMethod,
    }) {
      try {
        const respData = await ckeditorApiService.storeFileVersionAndDocumentMetadata({
          fileVersionDisplayId,
          comments,
          suggestions,
          isOriginalFile,
          fileName,
          fileSizeBytes,
          ancestorDisplayId,
          fileBase64,
          inputMethod,
        });
        return respData;
      } catch (err) {
        console.error(err);
        return null;
      }
    },

    async checkDocumentDuplicity(fileBase64) {
      const userId = this.harbourStore.contextDict.systemuserid;
      const hash = await this.calculateMD5FromBase64(fileBase64);
      const userUploads = uploadTimestamps[userId] || {};
      const currentTime = Date.now();

      if (userUploads[hash] && currentTime - userUploads[hash] < UPLOAD_TIME_LIMIT) {
        return true;
      }

      // Update the timestamp for the document upload
      userUploads[hash] = currentTime;
      uploadTimestamps[userId] = userUploads;

      return false;
    },

    // Function to calculate MD5 hash from base64 string using SparkMD5
    async calculateMD5FromBase64(base64String) {
      const cleanBase64String = base64String.replace(/^data:.*;base64,/, '');
      const binaryString = atob(cleanBase64String);
      const md5Hash = SparkMD5.hashBinary(binaryString);
      return md5Hash;
    },

    async importFromWord({ fileName, fileBase64, fileVersionDisplayId }) {
      const isDuplicate = await this.checkDocumentDuplicity(fileBase64);

      if (isDuplicate) {
        Toast.open({
          message: 'Please wait a moment before uploading the same document again.',
          type: 'is-danger',
        });
        Sentry.captureMessage('CK API call blocked!');
        console.error('CK API call blocked!');
        return;
      }

      try {
        const respData = await ckeditorApiService.importFromWord({
          fileName,
          fileBase64,
          fileVersionDisplayId,
        });
        return respData;
      } catch (err) {
        Sentry.captureException(err);
        console.error(err);
        return null;
      }
    },

    async storeVersionedHtml({ fileVersionDisplayId, updatedHtml }) {
      try {
        const respData = await ckeditorApiService.storeVersionedHtml({
          fileVersionDisplayId,
          updatedHtml,
        });
        return respData;
      } catch (err) {
        console.error(err);
        return null;
      }
    },

    async storeCkHtmlUpdate({ linkDisplayId, ckAgreementId, updatedHtml, customInputs }) {
      try {
        const respData = await ckeditorApiService.storeCkHtmlUpdate({
          linkDisplayId,
          ckAgreementId,
          updatedHtml,
          customInputs,
        });
        return respData;
      } catch (err) {
        console.error(err);
      }
    },

    async checkUserDocPermissions(ckFileId, linkDisplayId = null) {
      if (!ckFileId) return true;
      const toBool = [() => true, () => false];

      let url = `/document-token/${ckFileId}`;
      if (linkDisplayId) {
        url += `?link_display_id=${linkDisplayId}`;
      }
      return await axios.get(url).then(...toBool);
    },

    getCkAnnotationsForInput(inputId, ckeditorInstance) {
      const predicate = (value) =>
        value.item.name === 'annotation' && value.item.getAttribute('itemId') === inputId;
      const root = ckeditorInstance.model.document.getRoot();
      const range = ckeditorInstance.model.createRangeIn(root);
      const items = Array.from(range.getWalker({ ignoreElementEnd: true }));
      return items.filter(predicate);
    },

    updateMultipleUploadAnnotationsCk(settingsActiveInput, ckeditorInstance) {
      const isMultiple = settingsActiveInput.itemtypeconfig.multiple;
      const matchingAnnotations = this.getCkAnnotationsForInput(
        settingsActiveInput.id,
        ckeditorInstance,
      );
      const count = parseInt(settingsActiveInput.itemtypeconfig.imagescount);

      if (isMultiple) {
        // add annotations
        const writerCallback = ({ writer, annotation, model }) => {
          if (!annotationsToAdd[itemToAddIndex]) {
            return;
          }
          const position = model.createPositionAfter(annotation.item);
          const breakLine = writer.createElement('softBreak');
          model.insertContent(breakLine, position);

          const breakLinePosition = model.createPositionAfter(breakLine);
          const newAnnotation = writer.createElement(
            'annotation',
            annotationsToAdd[itemToAddIndex],
          );

          model.insertContent(newAnnotation, breakLinePosition);
          writer.setSelection(newAnnotation, 'on');
          itemToAddIndex++;
        };

        const annotationsToAdd = [];
        let itemToAddIndex = 0;

        if (count > matchingAnnotations.length) {
          for (let i = matchingAnnotations.length; i <= count - 1; i++) {
            annotationsToAdd.push(
              this.createCkeditorAnnotation({
                displayLabel: `{{ ${settingsActiveInput.itemdisplaylabel} }}`,
                itemId: settingsActiveInput.id,
                itemFieldType: settingsActiveInput.itemfieldtype,
                color: settingsActiveInput.itemcolor,
                isMultiple: true,
              }),
            );
            this.updateCkeditorAnnotations(
              ckeditorInstance,
              settingsActiveInput.id,
              writerCallback,
            );
          }
        } else if (matchingAnnotations.length > 1) {
          const ckAnnotationsIndexes = matchingAnnotations.map(
            (annotation) => annotation.item.index,
          );
          const indexesToKeep = ckAnnotationsIndexes.slice(0, count);

          this.updateCkeditorAnnotations(
            ckeditorInstance,
            settingsActiveInput.id,
            ({ writer, annotation }) => {
              if (indexesToKeep.includes(annotation.item.index)) return;

              writer.remove(annotation.item);
            },
          );
        }

        this.updateCkeditorAnnotationsMultipleAttribute(
          ckeditorInstance,
          settingsActiveInput.id,
          true,
        );
      } else {
        let counter = 0;
        this.updateCkeditorAnnotations(
          ckeditorInstance,
          settingsActiveInput.id,
          ({ writer, annotation }) => {
            if (counter > 0) {
              writer.remove(annotation.item);
            }
            counter++;
          },
        );

        this.updateCkeditorAnnotationsMultipleAttribute(
          ckeditorInstance,
          settingsActiveInput.id,
          false,
        );
      }
    },
  },
});

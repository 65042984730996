<script setup>
import '@static/superdoc/dist/style.css';

import Vue, { ref, onMounted, computed, getCurrentInstance } from 'vue';
import { HarbourSuperdoc } from '@static/superdoc/dist/harbour-superdoc.es.js';
import { useDraftsStore } from '@/pages/Drafts/stores/drafts-store';
import { BlankDOCX, getFileObject } from '@harbour-enterprises/superdoc';
import { createSuperdocDraft } from '@/domain/drafts/draft';
import { useHarbourStore } from '@/stores/harbour-store';
import { publishEvent } from '@/utils/bus';

const { proxy } = getCurrentInstance();
const draftsStore = useDraftsStore();
const harbourStore = useHarbourStore();

const emit = defineEmits(['close']);
const props = defineProps({
  file: {
    type: File,
    required: false,
  },
  superdocId: {
    type: String,
    required: false,
  },
  isBlankDocument: {
    type: Boolean,
    required: false,
  },
  superdocName: {
    type: String,
    required: false,
  },
  superdocPromise: {
    type: Promise,
    required: false,
  },
  contextDict: {
    type: Object,
    required: true,
  },
  isStandalone: {
    type: Boolean,
    required: false,
    default: false,
  },
  parent: {
    type: Object,
    required: false,
  },
  mode: {
    type: String,
    required: false,
  },
  template: {
    type: Object,
    required: false,
  }
});

const currentFile = ref(props.file || null);
const onNewSuperdoc = (superdoc) => {
  const draft = createSuperdocDraft(superdoc, {
    systemEmail: harbourStore.contextDict?.systememail,
  });
  publishEvent('drafts:created', draft);
};

const loadBlankFile = async () => {
  return await getFileObject(
    BlankDOCX,
    'new-superdoc.docx',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  );
};

/**
 * Open the full screen superdoc experience (harbour-superdoc). This will render
 * the Vue3 superdoc modal using SuperdocViewer component.
 * 
 * @param file Optional file to initialize with
 */
const init = async (file) => {
  if (props.isBlankDocument) file = await loadBlankFile();
  currentFile.value = file;
  const viewer = new HarbourSuperdoc({
    elementId: 'superdoc-viewer',
    file,
    superdocId: props.superdocId,
    superdocName: props.superdocName,
    context: props.contextDict,
    isStandalone: props.isStandalone,
    mode: props.mode,
    onNewSuperdoc,
    onDocumentShared,
    onUserRemoved,
    onContinueToAE,
    onTitleChange,
  });
  viewer.on('close', () => emit('close'));
};

const onDocumentShared = ({ id, collaborators }) => {
  draftsStore.onDocumentShared({ id, collaborators });
};

const onUserRemoved = ({ id, user }) => {
  draftsStore.onRemoveCollaborator({ id, user });
};

const onContinueToAE = ({ superdoc, mode }) => {
  const agreementEditorProps = {
    parent: proxy,
    creationMode: mode,
    superdoc,
    fullScreen: true,
    agreementId: superdoc.id,
  };

  if (props.template) {
    agreementEditorProps.agreementId = props.template.agreement_id;
    agreementEditorProps.templateGroupId = props.template.template_group_id;
  }

  Vue.prototype.$openAgreementEditorModal({ props: agreementEditorProps })
  emit('close');
};

const onTitleChange = ({ id, title }) => {
  draftsStore.renameDraftWithNotify(id, title);
};

onMounted(async () => {
  init(props.file);
});

const getSuperdocModalStyle = computed(() => {
  const maxWidth = window.innerWidth;
  const maxHeight = window.innerHeight;
  return {
    maxWidth: `${maxWidth}px`,
    maxHeight: `${maxHeight}px`,
  };
});

const hrbrSuperdocStyle = {
    borderRadius: props.isStandalone ? '0' : '8px',
    padding: 0,
    margin: 0,
    minHeight: props.isStandalone ? '100%' : '98%',
    minWidth: props.isStandalone ? '100%' : '98%',
}
</script>

<template>
  <div class="superdoc-viewer-full-screen" :style="getSuperdocModalStyle">
    <div class="hrbr-superdoc" :style="hrbrSuperdocStyle">
      <div id="superdoc-viewer" class="viewer-container"></div>
    </div>
  </div>
</template>

<style>
.hrbr-superdoc button.modal-close > * {
  color: black !important;
  display: none;
}
.hrbr-superdoc button.modal-close.is-large::before,
.hrbr-superdoc button.modal-close.is-large::after {
  color: black !important;
}
</style>

<style scoped>
.superdoc-loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.superdoc-viewer-full-screen {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hrbr-superdoc {
  border-radius: 8px;
  background-color: white;
  min-width: 98%;
  min-height: 98%;
  height: 98%;
  max-width: 98%;
  max-height: 98%;
  position: relative;
}
.viewer-container {
  width: 100%;
  height: 100%;
  max-width: 100vw;
  max-height: 100vh;
}
</style>
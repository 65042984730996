export const COORDINATES_CONVERT_COEF = 300 / 72;

export const MAGIC_ANNOTATIONS_SIZES = {
  checkbox: {
    w: 50,
    h: 50,
  },
  image: {
    w: 250,
    h: 250,
  },
  signature: {
    w: 600,
    h: 200,
  },
  defaultItem: {
    w: 600,
    h: 60,
  },
};

// Min width for date field (before scale)
export const MIN_DATE_FIELD_WIDTH = 330;
export const SIGNATURE_MIN_HEIGHT = 100;

export const INPUT_DPI = 300;
export const OUTPUT_DPI = 72;
// to calc padding as percentage from height and width
export const ANNOTATION_PADDING_VERTICAL = 0.1;
export const ANNOTATION_PADDING_HORIZONTAL = 0.01;
